#purpose {
    --progress: 0;
    padding: 248px 0 0 0;
    @media (max-width: 991px) {
        padding: 180px 0 0 0;
    }
    h1 {
        font-family: "termina", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 38px;
        line-height: 57px;
        letter-spacing: 15.2px;
        margin-left: 12px;
        color: #CC9C4A;

        @media(max-width: 991px) {
            font-size: 26px;
            text-align: center;
            line-height: 35px;
            margin-bottom: 32px;
        }
    }

    p {
        color: #000;
        font-family: Playfair;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
        margin-bottom: 20px;

        @media(max-width: 991px) {
            font-size: 14px;
            text-align: center;
            line-height: 21px;
            margin-bottom: 12px;
        }
    }

    .subtitle {
        color: #0A0A0A;
        font-family: "termina", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 4.8px;
        text-transform: uppercase;

        @media(max-width: 991px) {
            font-size: 10px;
            line-height: 21px;
            text-align: center;
        }
    }

    .title-section {
        color: #CC9C4A;
        font-family: Playfair;
        font-size: 45px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px;
        .line{
            justify-content: start;
        }
        @media(max-width: 991px) {
            font-size: 32px;
            line-height: 28px;
            text-align: center;
            .line{
                justify-content: center;
            }
        }
    }

    .section1 {
        padding-bottom: 188px;

        @media(max-width: 991px) {
            padding-bottom: 80px;

            .row {
                flex-direction: column-reverse;
                padding-bottom: 0;
            }
        }

        .subtitle {
            margin-top: 9px;
            margin-left: 16px;
            margin-bottom: 12px;

            @media(max-width: 991px) {
                margin-left: 0;
            }
        }

        .title-section {
            margin-left: 19px;
            max-width: 439px;
            margin-bottom: 43px;

            @media(max-width: 991px) {
                margin-left: 0;
            }
        }

        p {
            margin-left: 83px;

            @media(max-width: 991px) {
                margin-left: 0;
            }
        }

        .galeria {
            width: 665px;
            height: 444px;
            position: absolute;
            left: -45px;
            top: 8px;

            @media(max-width: 1500px) {
                width: 100%;
            }

            @media(max-width: 991px) {
                position: inherit;
                left: 0;
                top: 0;
                height: 286px;
                margin-top: 56px;
                margin-bottom: 0px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .slider-component {
                aspect-ratio: 1.2;
                position: relative;
                margin-bottom: 73px;

                img {
                    width: 100%;
                    object-fit: cover;
                }

                .progress-bar {
                    width: 80%;
                    height: 2px;
                    background-color: rgba(255, 255, 255, 0.5);
                    border-radius: 2px;
                    overflow: hidden;
                    position: absolute;
                    bottom: 30px;
                    left: 10%;
                    z-index: 9;
                    transform: matrix(-1, 0, 0, -1, 0, 0);
                }

                .inside-bar {
                    transform-origin: left center;
                    border-radius: 2px;
                    background-color: rgb(255, 255, 255);
                    height: 2px;
                    transform: scaleX(var(--progress));
                }


            }

            .content--image {
                display: flex;
                width: 100%;
                height: 444px;
                overflow: hidden;
                position: relative;

                @media(max-width: 991px) {
                    height: 286px;
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    inset: 0;
                }

                .image-wrap {
                    width: 100% !important;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    .bg-section {
        background: url('../img/purpose/bg-proposito2.webp') bottom center no-repeat;
        background-size: contain;
    }

    .section2 {
        position: relative;
        padding-bottom: 0px;

        .btn-find {
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
            /* 230.769% */
            letter-spacing: 3.9px;
            color: #0A0A0A;
            text-transform: uppercase;
            text-decoration: none;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-inline: 1.5rem;
            position: relative;
            font-family: 'Termina Test Demi';
            width: fit-content;
            margin: 0 auto;

            &:hover {
                &::after {
                    width: 100%;
                }
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 40%;
                height: 1px;
                background: #CC9C4A;
                transition: all .2s ease-in;
            }
        }

        .text-destaque {
            color: #000;
            text-align: center;
            font-family: 'Playfair';
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;
            max-width: 58%;
            margin: 0 auto;
            margin-bottom: 50px;
            margin-top: 118px;
        }

        .img-gif {
            width: 100%;
            max-height: 630px;
            object-fit: cover;

            @media(max-width: 991px) {
                max-height: 430px;
            }
        }

        .text-marquee {
            position: absolute;
            top: 37%;

            @media(max-width: 991px) {
                max-width: 100%;
            }
        }

        .container {
            max-width: 1425px;
            margin: 0 auto;
        }

        .content--image {
            display: flex;
            width: 100%;
            height: 630px;
            overflow: hidden;
            position: relative;

            @media(max-width: 991px) {
                height: 430px;
            }

            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                position: absolute;
                inset: 0;
            }
        }

        .enter-image-style {
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .section3 {
        padding: 156px 0 147px 0;

        @media(max-width: 991px) {
            padding: 86px 0 43px 0;
        }

        .subtitle {
            margin-left: 41px;
            margin-bottom: 11px;

            @media(max-width: 991px) {
                margin-left: 0;
            }
        }

        .title-section {
            margin-left: 41px;
            margin-bottom: 44px;

            @media(max-width: 991px) {
                margin-left: 0;
            }
        }

        p {
            margin-left: 106px;
            max-width: 553px;

            @media(max-width: 991px) {
                margin-left: 0;
            }
        }

        .galeria {
            width: 682px;
            height: 548px;
            position: absolute;
            right: -89px;

            @media(max-width: 1500px) {
                width: 100%;
            }

            @media(max-width: 991px) {
                position: inherit;
                right: 0;
                height: 361px;
                margin-top: 56px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .slider-component {
                aspect-ratio: 1.2;
                position: relative;
                margin-bottom: 73px;

                img {
                    width: 100%;
                    object-fit: cover;
                }

                .progress-bar {
                    width: 80%;
                    height: 2px;
                    background-color: rgba(255, 255, 255, 0.5);
                    border-radius: 2px;
                    overflow: hidden;
                    position: absolute;
                    bottom: 30px;
                    left: 10%;
                    z-index: 9;
                    transform: matrix(-1, 0, 0, -1, 0, 0);
                }

                .inside-bar {
                    transform-origin: left center;
                    border-radius: 2px;
                    background-color: rgb(255, 255, 255);
                    height: 2px;
                    transform: scaleX(var(--progress));
                }


            }

            .content--image {
                display: flex;
                width: 100%;
                height: 548px;
                overflow: hidden;
                position: relative;

                @media(max-width: 991px) {
                    height: 361px;
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    inset: 0;
                }
            }
        }
    }

    .section4 {
        padding-bottom: 441px;

        @media(max-width: 991px) {
            padding-bottom: 130px;

            .row {
                flex-direction: column-reverse;
            }
        }

        .subtitle {
            margin-top: 289px;
            margin-left: 58px;
            margin-bottom: 12px;

            @media(max-width: 991px) {
                margin-left: 0;
                margin-top: 0;
            }
        }

        .title-section {
            margin-left: 58px;
            max-width: 439px;
            margin-bottom: 42px;

            @media(max-width: 991px) {
                margin-left: 0;
            }
        }

        p {
            margin-left: 123px;

            @media(max-width: 991px) {
                margin-left: 0;
            }
        }

        .galeria {
            width: 694px;
            height: 733px;
            position: absolute;
            left: -62px;
            top: 91px;

            @media(max-width: 1500px) {
                width: 100%;
            }

            @media(max-width: 991px) {
                position: inherit;
                left: 0;
                top: 0;
                height: 444px;
                margin-top: 56px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .slider-component {
                aspect-ratio: 1.2;
                position: relative;
                margin-bottom: 73px;

                img {
                    width: 100%;
                    object-fit: cover;
                }

                .progress-bar {
                    width: 80%;
                    height: 2px;
                    background-color: rgba(255, 255, 255, 0.5);
                    border-radius: 2px;
                    overflow: hidden;
                    position: absolute;
                    bottom: 30px;
                    left: 10%;
                    z-index: 9;
                    transform: matrix(-1, 0, 0, -1, 0, 0);
                }

                .inside-bar {
                    transform-origin: left center;
                    border-radius: 2px;
                    background-color: rgb(255, 255, 255);
                    height: 2px;
                    transform: scaleX(var(--progress));
                }


            }

            .content--image {
                display: flex;
                width: 100%;
                height: 733px;
                overflow: hidden;
                position: relative;

                @media(max-width: 991px) {
                    position: inherit;
                    right: 0;
                    height: 444px;
                    margin-top: 56px;
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    inset: 0;
                }

                .image-wrap {
                    width: 100% !important;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    .section6 {
        padding: 160px 0 163px 0;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;

        .container{
            z-index: 2;
            position: relative;
        }

        @media(max-width: 991px) {
            padding: 100px 0 100px 0;
        }

        .subtitle,
        .title-section {
            color: #fff;
            text-align: center;
        }

        .subtitle {
            margin-bottom: 12px;
        }

        .title-section {
            font-size: 80px;
            line-height: 65px;
            margin-bottom: 86px;
            .line{
                justify-content: center;
            }
            @media(max-width: 991px) {
                font-size: 56px;
                line-height: 50px;
            }
        }

        .btn-section {
            display: inline-block;
            text-decoration: none;
            position: relative;
            font-family: "termina", sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
            letter-spacing: 3.9px;
            color: #ffffff;
            text-transform: uppercase;

            &::before {
                position: absolute;
                bottom: -13px;
                left: -27px;
                content: "";
                width: 121px;
                height: 2.5px;
                background-color: #CC9C4A;
            }
        }

        .btn2 span {
            color: #ffffff;
        }

        .btn2-4 span:hover {
            color: #CC9C4A;
        }

        .layer {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 1;
        }
    }

}
