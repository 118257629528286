#places {
    .mySwiper3{
        .swiper-slide-active img{
            animation: fadein 1.8s;
            animation-timing-function:  cubic-bezier(0.000, 0.280, 0.435, 1.060);
        }
    }
    @keyframes fadein {
        0% {
            transform: scale(1.07);
        }
        100% {
            transform: scale(1);
        }
    }
    .banner-margin {
        min-height: 95dvh;
        z-index: 9;
        @media(max-width: 991px) {
            margin: 0px;
        }

        position: relative;

        .slider-full {
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 95dvh;

            .swiper-button-prev,
            .swiper-rtl .swiper-button-next,
            .swiper-button-next,
            .swiper-rtl .swiper-button-prev {
                width: 50px;
                height: 50px;

                &::after {
                    display: none;
                }
            }

            .swiper-button-prev {
                left: inherit;
                top: inherit;
                bottom: 200px;
                right: 360px;

                @media(max-width: 991px) {
                    bottom: 86px;
                    right: 97px;
                }
            }

            .swiper-button-next {
                right: 300px;
                top: inherit;
                bottom: 200px;


                @media(max-width: 991px) {
                    bottom: 86px;
                    right: 27px;
                }
            }

            .swiper {
                height: 95dvh;

                @media(max-width: 991px) {
                    height: 100%;
                }
            }

            .swiper-slide {
                position: relative;

                &::after {
                    content: '';
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: rgba(0, 0, 0, 0.5);
                }
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .title {
            color: var(--white, #FFF);
            font-family: "termina", sans-serif;
            font-size: 44px;
            font-style: normal;
            font-weight: 400;
            line-height: 58px;
            /* 131.818% */
            letter-spacing: 17.6px;
            margin-bottom: 15px;
            .line{
                justify-content: start;
            }
        }

        .subtitle {
            color: var(--white, #FFF);
            font-family: "termina", sans-serif;
            font-size: 28px;
            font-style: normal;
            font-weight: 400;
            line-height: 38px;
            /* 135.714% */
            letter-spacing: 11.2px;
            text-transform: uppercase;
            margin-bottom: 17px;
        }

        .text {
            color: var(--white, #FFF);
            font-family: 'Playfair';
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;
            /* 150% */
        }

        .btn-down2 {
            cursor: pointer;
            width: 76px;
            height: 76px;
            background: url('../img/seta.svg') center center no-repeat;
            background-size: contain;
            overflow: hidden;
            display: block;
            margin-top: 10dvh;
            margin-left: 12px;
            @media(max-width: 991px) {
                margin-top: 30px;
                margin-bottom: 50px;
                position: relative;
            }
        }

        .ca3-scroll-down-arrow {
            background-image: url('../img/seta-home.png');
            background-size: contain;
            background-repeat: no-repeat;
        }

        .ca3-scroll-down-link {
            cursor: pointer;
            height: 30px;
            width: 15px;
            position: absolute;
            left: 0;
            right: 0;
            top: 20px;
            margin: 0 auto;
            z-index: 100;
            -webkit-animation: ca3_fade_move_down 1s ease-in-out infinite;
            -moz-animation: ca3_fade_move_down 1s ease-in-out infinite;
            animation: ca3_fade_move_down 1s ease-in-out infinite;
        }


        /*animated scroll arrow animation*/
        @-webkit-keyframes ca3_fade_move_down {
            0% {
                -webkit-transform: translate(0, -20px);
                opacity: 0;
            }

            50% {
                opacity: 1;
            }

            100% {
                -webkit-transform: translate(0, 20px);
                opacity: 0;
            }
        }

        @-moz-keyframes ca3_fade_move_down {
            0% {
                -moz-transform: translate(0, -20px);
                opacity: 0;
            }

            50% {
                opacity: 1;
            }

            100% {
                -moz-transform: translate(0, 20px);
                opacity: 0;
            }
        }

        @keyframes ca3_fade_move_down {
            0% {
                transform: translate(0, -20px);
                opacity: 0;
            }

            50% {
                opacity: 1;
            }

            100% {
                transform: translate(0, 20px);
                opacity: 0;
            }
        }

        .content {
            padding-top: 36%;
            position: relative;
            z-index: 1;

            @media(max-width: 991px) {
                padding-top: 50%;
                padding-left: 15px;
                padding-right: 15px;
            }
        }

        #breadcrumb ul {
            margin-bottom: 10dvh;
            @media (max-width: 991px) {
                margin-bottom: 50px;
            }
        }
    }

    .lugar {
        padding-top: 150px;
        position: relative;

        @media(max-width: 991px) {
            padding-top: 50px;
        }

        .progress-bar {
            --progress: 0;
            width: 80%;
            height: 2px;
            background-color: #CC9C4A;
            border-radius: 2px;
            overflow: hidden;
            position: absolute;
            bottom: 30px;
            left: 10%;
            z-index: 9;
            transform: matrix(-1, 0, 0, -1, 0, 0);
        }

        .inside-bar {
            transform-origin: left center;
            border-radius: 2px;
            background-color: #fff;
            height: 2px;
            transform: scaleX(var(--progress));
        }

        .nav-lugar {
            position: absolute;
            top: 150px;
            left: 10%;
            display: flex;
            gap: 64px;
            flex-direction: column;
            opacity: 0;
            @media (max-width: 1399px) {
                left: 2%;
            }
            @media(max-width: 991px) {
                display: none;
            }

            &--title {
                color: var(--accent-color, #CC9C4A);
                font-family: 'Playfair';
                font-size: 25px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                /* 96% */
                position: relative;
                max-width: 50%;
                text-decoration: none;
                transition: all 0.5s ease-in-out;

                &::after {
                    content: '';
                    position: absolute;
                    left: -12vw;
                    bottom: -32px;
                    width: 20vw;
                    background: rgb(204, 156, 74);
                    background: linear-gradient(90deg, rgba(204, 156, 74, 1) 55%, rgba(0, 54, 68, 1) 55%);
                    height: 2px;
                    transform-origin: left center;
                    transform: scaleX(0);
                    transition: all 0.5s ease-in-out;
                }

                &.active {
                    color: #003644;

                    &::after {
                        transform: scaleX(1);
                    }
                }
            }
        }

        .grid-lugar {
            display: grid;
            grid-template-columns: 602px 537px;
            gap: 50px;
            margin-bottom: 150px;
            @media (max-width: 1550px) {
                grid-template-columns: 500px 400px;
            }
            @media(max-width: 991px) {
                display: block;
                margin-bottom: 50px;
            }
        }

        .subtitle {
            color: #0A0A0A;
            /* desktop/Legend */
            font-family: "termina", sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            /* 250% */
            letter-spacing: 4.8px;

            @media(max-width: 991px) {
                text-align: center;
                font-size: 10px;
                line-height: 21px;
                letter-spacing: 4px;
                text-align: center;
            }
        }

        .title {
            color: var(--accent-color, #CC9C4A);
            /* desktop/H3 - Title */
            font-family: 'Playfair';
            font-size: 45px;
            font-style: normal;
            font-weight: 500;
            line-height: 40px;
            /* 88.889% */
            margin-bottom: 40px;
            margin-top: 15px;
            .line{
                justify-content: start;
            }
            @media(max-width: 991px) {
                text-align: center;
                font-size: 32px;
                line-height: 28px;
                .line{
                    justify-content: center;
                }
            }
        }

        .text {
            color: var(--Black, #0A0A0A);
            /* desktop/H6 - textBody */
            font-family: 'Playfair';
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;
            /* 150% */
            padding-left: 65px;

            @media(max-width: 991px) {
                font-size: 14px;
                line-height: 21px;
                padding-left: 0;
                text-align: center;
            }
        }

        .title-place {
            color: var(--Black, #0A0A0A);
            /* desktop/H4 - Subtitle */
            font-family: 'Playfair';
            font-size: 30px;
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            /* 66.667% */
            margin-bottom: 30px;
            padding-left: 65px;

            @media(max-width: 991px) {
                text-align: center;
                font-size: 21px;
                line-height: 14px;
                padding-left: 0px;
            }
        }

        .main-box {
            .slider-component {
                width: 100%;
                height: 503px;
                margin-top: 119px;
                margin-bottom: 80px;

                @media(max-width: 991px) {
                    margin-bottom: 50px;
                    height: 309px;
                    img{
                        width: 100%;
                    }
                }
            }
        }

        .secondry-box {
            .slider-component {
                height: 546px;
                width: 100%;
                margin-bottom: 115px;
                @media (max-width: 991px) {
                    margin-bottom: 50px;
                    height: 309px;
                    img{
                        width: 100%;
                    }
                }
            }
        }
    }

    .banner-paralax {
        height: 545px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 99;
        position: relative;
        &::after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #000;
            opacity: .3;
        }
        .btn2 {
            width: fit-content;
        }

        .paralax-content {
            max-width: 730px;
            display: flex;
            flex-direction: column;
            gap: 40px;
            align-items: center;
            position: relative;
            z-index: 9;
            .btn2 span {
                color: #ffffff;
            }

            .btn2-4 span:hover {
                color: #CC9C4A;
            }
        }

        .title {
            color: #FFF;
            text-align: center;
            /* desktop/H1 - Title */
            font-family: Playfair;
            font-size: 80px;
            font-style: normal;
            font-weight: 300;
            line-height: 65px;

            /* 81.25% */
            strong {
                color: #CC9C4A;
            }
        }

        .text {
            color: #FFF;
            text-align: center;
            font-family: Playfair;
            font-size: 30px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;
            /* 100% */
        }

        .btn-find {
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
            /* 230.769% */
            letter-spacing: 3.9px;
            color: #fff;
            text-transform: uppercase;
            text-decoration: none;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-inline: 1.5rem;
            position: relative;
            font-family: 'Termina Test Demi';
            width: fit-content;
            margin: 0 auto;

            &:hover {
                &::after {
                    width: 100%;
                }
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 40%;
                height: 1px;
                background: #CC9C4A;
                transition: all .2s ease-in;
            }
        }
    }
}
