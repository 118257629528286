.preloader{
    height: 100%;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

.logo-header {
    position: absolute;
    left: 18px;
    top: 27px;
    z-index: 999;
    &.menu-logo {
        left: 126px;
        top: 42px;

        @media(max-width: 991px) {
            left: 15px;
            top: 52px;
        }
    }

    @media(max-width: 991px) {
        left: -1px;
        top: 36px;

        img {
            max-width: 110px;
        }
    }
}
//fixed contato item
.contato-fix{
    position: absolute;
    left: 126px;
    bottom: 60px;
    span{
        color: #fff;
    }
    @media (max-width: 991px) {
        left: 15px;
        bottom: 30px;
    }
}

//redes fixed
.redes-fix{
    position: absolute;
    right: 15vw;
    bottom: 60px;
    display: flex;
    gap: 18px;
    .redes-icon{
        width: 41px;
        height: 41px;
        border: 1px solid #CC9C4A;
        transition: all .1s ease-in-out;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        path{
            transition: all .1s ease-in-out;
        }
        &:hover{
            background-color: #CC9C4A;
            path{
                fill: #fff;
            }
        }
    }
    @media (max-width: 991px) {
        right: 15px;
        bottom: 30px;
    }
}



// BEGIN NAV

$toggle-size: 32px;
$bar-size: $toggle-size / 8;
$bar-space: (
    $toggle-size - ($bar-size * 5)) / 2;

$bar2-top: $bar-size + $bar-space;
$bar3-top: $bar2-top * 2;

#nav-container {
    margin-left: auto;
    position: relative;
}

#menu-icon {
    position: fixed;
    right: 15vw;
    top: 40px;
    padding: 18px;
    border: solid 1px #CC9C4A;
    border-radius: 37px;
    z-index: 999;
    transition: all .2s ease-in-out;
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
    background-color: rgba(0, 0, 0, 0.1294117647);
    &:hover{
        -webkit-backdrop-filter: blur(10px);
        backdrop-filter: blur(10px);
        background-color: rgba(0, 0, 0, 0.1294117647);
    }
    @media (max-width: 991px) {
        right: 15px;
    }
}

#nav-toggle {
    display: block;
    width: $toggle-size;
    height: $toggle-size;
    z-index: 3;

    cursor: pointer;

    transform: rotate(0deg
    );
}

#nav-toggle span {
    background-color: black;
    width: 100%;
    height: 2px;

    border-radius: $bar-size / 4;

    display: block;
    position: absolute;
    left: 0;
    content: '';

    // transition time and time function
    transition: .5s ease-in-out;
}

#nav-toggle span:nth-child(1) {
    top: $bar-size;
    transform-origin: left center;
}

#nav-toggle span:nth-child(2) {
    top: $bar2-top + $bar-size;
    transform-origin: left center;
}

#nav-toggle span:nth-child(3) {
    top: $bar3-top + $bar-size;
    transform-origin: left center;
}

#nav-toggle.open span:nth-child(1) {
    transform: rotate(45deg);
    top: -($toggle-size / 32) + $bar-size;
    left: $bar-size;
}

#nav-toggle.open span:nth-child(2) {
    width: 0%;
    opacity: 0;
}

#nav-toggle.open span:nth-child(3) {
    transform: rotate(-45deg);
    top: $bar3-top + ($toggle-size / 32) + $bar-size;
    left: $bar-size;
}

#nav-overlay {
    position: absolute;
    top: $toggle-size / 2;
    left: 150%;

    z-index: -1;

    background: rgba(0, 0, 0, 0.8);

    border-radius: 50%;

    transition: 1s;
    transform: scale3d(0, 0, 0);
}

#nav-overlay.open {
    transform: scale3d(1, 1, 1);
    z-index: 2;
}

#nav-fullscreen {
    width: 100%;

    position: fixed;
    left: 0;
    top: 0;

    transition: ease-in-out 0.5s;
    transition-delay: 0.15s;

    visibility: hidden;
    opacity: 0;
}

#nav-fullscreen.open {
    visibility: visible;
    opacity: 1;
    transition: ease-in-out 0.5s;
    transition-delay: 0.25s;
    z-index: 999;

    @media(max-width: 991px) {
        height: 100%;
        overflow: auto;
    }
}

html,
body {
    height: 100%;
}

body {
    overflow-x: hidden;
}

header {
    display: flex;
    padding: 16px;
    width: 100%;
    height: 150px;
    overflow: hidden;
    position: absolute;

    &.heigh-all {
        height: 100vh;
    }
}

#nav-overlay {
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

#nav-toggle span {
    background-color: #CC9C4A;
}

#nav-toggle.yellow span {
    background-color: #CC9C4A;
}

#nav-fullscreen {
    display: flex;
    align-items: center;
    justify-content: center;
}

#wrap {
    margin-left: 182px;
    margin-top: 84px;

    &.d-none {
        display: none;
    }

    @media(max-width: 991px) {
        margin-left: 0px;
        margin-top: 100px;
    }
}

.image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    opacity: 0;
    visibility: hidden;
    transition: all .3s ease-in-out;
}

.image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50% 50%;
    filter: brightness(0.5);
}

.container>ul>li:first-child .image {
    opacity: 1;
    visibility: visible;
}


.link {
    color: #FFF;
    font-family: Playfair;
    font-size: 55px;
    font-style: normal;
    font-weight: 500;
    line-height: 75px;
    text-decoration: none;
    display: block;
    transition: all .5s ease-in;

    @media(max-width: 991px) {
        font-size: 38px;
        line-height: 52px;
    }


    &:hover,
    &.active {
        transition: all .5s ease-in;
        color: #CC9C4A;
    }
}
.container-item{
    position: relative;
    &::after {
        position: absolute;
        left: -69px;
        content: ' ';
        width: 0;
        height: 1px;
        background-color: #CC9C4A;
        transition: all .5s ease-in;

    }
}

.container-item.active {
    position: relative;
    transition: all .5s ease-in;
    width: fit-content;
    &::after {
        width: calc(100% + 69px);
    }
}

.container-item.active .link+.image {
    opacity: 1;
    visibility: visible;
    animation: fadein 1.8s;
    animation-timing-function:  cubic-bezier(0.000, 0.280, 0.435, 1.060);

}

.container-item.active .link {
    opacity: 1;
}
@keyframes fadein {
    0% {
        transform: scale(1.07);
    }
    100% {
        transform: scale(1);
    }

}
