#wine {
    background: #003644;

    @media (max-width: 1100px) {
        padding-top: 200px;
    }

    .container {
        max-width: 1234px;
    }

    .content-load {
        display: grid;
        grid-template-columns: .5fr .5fr 1fr;
        height: 100dvh;
        align-items: center;

        @media (max-width: 1100px) {
            display: flex;
            flex-direction: column;
            height: auto;
            .wine-content{
                margin-top: 5rem;
            }
        }
    }

    .content-load2 {
        display: grid;
        grid-template-columns: .5fr .5fr 1fr;
        min-height: 90dvh;
        padding-bottom: 200px;
        margin-top: 200px;

        @media (max-width: 1100px) {
            display: flex;
            flex-direction: column;
        }

        @media (max-width: 991px) {
            display: block !important;
            flex-direction: column;
            padding-bottom: 0px;
            margin-top: 0px;
            .box{
                display: none;
            }
        }

        .btn-box {
            position: relative;
            top: 40%;
            transform: scale(.8);
            left: -10%;
        }

        .sub {
            margin-bottom: 40px;
            margin-top: 40px;
        }
    }
    .wrapper-wine{
        position: relative;
    }


    .wine-content {
        transform: translate(13px, -45px);
        padding-left: 1rem;
        padding-top: 5rem;

        @media (max-width: 1299px) {
            transform: scale(.8);
        }

        @media (max-height: 799px) {
            transform: scale(1);
        }
    }

    .wine-img {
        span {
            color: #fff;

            &:hover {
                color: #CC9C4A;
            }
        }

        .btn2 {
            position: fixed;
            left: 32.5%;
            bottom: 10%;
            opacity: 0;
            z-index: 9999;

            @media (max-width: 1599px) {
                left: 30.5%;
            }

            @media (max-width: 1299px) {
                left: 26%;
            }
            @media (max-width: 1100px) {
                opacity: 1;
                position: relative;
                bottom: 0;
                left: 17%;
                bottom: -33px;
            }
        }

        .wine-name {
            color: #FFF;
            text-align: center;
            font-family: 'Playfair';
            font-size: 100px;
            font-style: normal;
            font-weight: 500;
            line-height: 80px;
            position: fixed;
            left: 29.5%;
            bottom: 34%;
            max-width: 22%;
            opacity: 0;
            transition: all .5s ease-in-out;
            transform: scale(.8);
            @media (max-width: 1599px) {
                left: 25.5%;
            }
            @media (max-width: 1299px) {
                transform: scale(.8);
                left: 21%;
                max-width: 30%;
            }

            @media (max-width: 1100px) {
                position: relative;
                left: 0;
                top: 0;
                font-size: 70px;
                line-height: 70px;
                max-width: 100%;
                opacity: 1;
            }
        }

        img {
            position: fixed;
            left: 32%;
            bottom: 20%;
            max-height: 549px;
            z-index: 1;
            pointer-events: none;
            transform: scale(1.2);
            @media (max-width: 1599px) {
                left: 29%;
                top: 20%;
            }
            @media (max-width: 1299px) {
                transform: scale(.8);
                left: 25%;
            }
            @media (max-width: 1100px) {
                position: relative;
                left: 0;
                top: 0;
            }
            @media (max-width: 991px) {
                position: relative;
                left: 16%;
                top: 0;
                max-height: 358px;
                transform: scale(1);
            }
        }
    }

    .title {
        font-size: 122px;
        line-height: 100px;
        font-family: 'Playfair';
        color: #fff;
        margin-bottom: 37px;
        .line{
            justify-content: start;
        }
        @media (max-width: 991px) {
            font-size: 62px;
            line-height: 62px;
        }
    }

    .sub {
        color: #FFF;
        font-family: 'Playfair';
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        /* 53.333% */
        margin-bottom: 2rem;
    }

    .subtitle {
        color: #FFF;
        font-family: "termina", sans-serif;
        font-size: 20px;
        font-style: normal;
        font-weight: 300;
        line-height: 16px;
        margin-bottom: 41px;
    }

    .text {
        color: #FFF;
        font-family: "termina", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 25px;
        /* 208.333% */
    }

    .desc {
        color: #FFF;
        font-family: "termina", sans-serif;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 25px;

        span {
            color: #FFF;
            font-family: "termina", sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 600;
            line-height: 25px;
            /* 208.333% */
        }
    }

    .btn-box {
        display: flex;
        gap: 23px;
        text-decoration: none;
        align-items: center;
        margin-top: 50px;

        span {
            color: #FFF;
            font-family: ' Playfair';
            font-size: 18px;
            font-style: normal;
            font-weight: 500;
            line-height: 16.5px;
            /* 91.667% */
        }
    }

    .btn-down2 {
        cursor: pointer;
        width: 76px;
        height: 76px;
        bottom: 77px;
        left: 16.6vw;
        background: url(../img/seta.svg) center center no-repeat;
        background-size: contain;
        overflow: hidden;

    }

    .ca3-scroll-down-link {
        cursor: pointer;
        height: 30px;
        width: 15px;
        position: absolute;
        left: 0;
        right: 0;
        top: 20px;
        margin: 0 auto;
        z-index: 100;
        -webkit-animation: ca3_fade_move_down 1s ease-in-out infinite;
        -moz-animation: ca3_fade_move_down 1s ease-in-out infinite;
        animation: ca3_fade_move_down 1s ease-in-out infinite;
    }

    .ca3-scroll-down-arrow {
        background-image: url('../img/seta-home.png');
        background-size: contain;
        background-repeat: no-repeat;
    }

    /*animated scroll arrow animation*/
    @-webkit-keyframes ca3_fade_move_down {
        0% {
            -webkit-transform: translate(0, -20px);
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            -webkit-transform: translate(0, 20px);
            opacity: 0;
        }
    }

    @-moz-keyframes ca3_fade_move_down {
        0% {
            -moz-transform: translate(0, -20px);
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            -moz-transform: translate(0, 20px);
            opacity: 0;
        }
    }

    @keyframes ca3_fade_move_down {
        0% {
            transform: translate(0, -20px);
            opacity: 0;
        }

        50% {
            opacity: 1;
        }

        100% {
            transform: translate(0, 20px);
            opacity: 0;
        }
    }

    .wines-box {
        display: flex;
        flex-direction: column;
        gap: 70px;
        position: fixed;
        top: 47vh;
        @media (max-width: 1100px) {
            flex-direction: row;
            overflow-x: scroll;
            width: 100vw;
            overflow-y: hidden;
            height: 100px;
            padding-inline: 2rem;
            position: inherit;
            top: inherit;
        }
    }

    .card-wine {
        display: flex;
        gap: 28px;
        text-decoration: none;
        align-items: center;
        position: relative;

        &::after {
            content: "";
            position: absolute;
            bottom: -21px;
            width: 198%;
            height: 2px;
            background-color: #fff;
            left: -140%;
            transition: all .5s ease-in-out;
            transform-origin: left center;
            transform: scaleX(0);
        }

        &.active {
            &::after {
                transform: scaleX(1);
            }
        }

        img {
            max-height: 57px;
            transition: all .5s ease-in-out;
        }

        p {
            color: #CC9C4A;
            font-family: ' Playfair';
            font-size: 25px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            /* 96% */
            max-width: 50%;
            margin-bottom: 0;
            transition: all .5s ease-in-out;
        }

        &:hover {
            p {
                color: #fff;
            }

            &::after {
                transform: scaleX(1);
            }
        }
    }
}

.wine-page {
    .banner-paralax {
        height: 545px;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 999;
        position: relative;
        .btn2 {
            &::after {
                content: '';
                position: relative;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: #000;
                opacity: .3;
            }
        }

        .btn2 {
            width: fit-content;
        }

        .paralax-content {
            max-width: 730px;
            display: flex;
            flex-direction: column;
            gap: 40px;
            align-items: center;

            position: relative;
            z-index: 9;

            .btn2 span {
                color: #ffffff;
            }

            .btn2-4 span:hover {
                color: #CC9C4A;
            }
        }

        .title {
            color: #FFF;
            text-align: center;
            /* desktop/H1 - Title */
            font-family: Playfair;
            font-size: 80px;
            font-style: normal;
            font-weight: 300;
            line-height: 65px;

            /* 81.25% */
            strong {
                color: #CC9C4A;
            }
        }

        .text {
            color: #FFF;
            text-align: center;
            font-family: Playfair;
            font-size: 30px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;
            /* 100% */
        }

        .btn-find {
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
            /* 230.769% */
            letter-spacing: 3.9px;
            color: #fff;
            text-transform: uppercase;
            text-decoration: none;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-inline: 1.5rem;
            position: relative;
            font-family: 'Termina Test Demi';
            width: fit-content;
            margin: 0 auto;

            &:hover {
                &::after {
                    width: 100%;
                }
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 40%;
                height: 1px;
                background: #CC9C4A;
                transition: all .2s ease-in;
            }
        }
    }
}
