.form-group {
    position: relative;
    margin: 0;
}

input.error,
textarea.error,
select.error {
    background-color: #fce4e4;
    border: 1px solid #cc0033;
    outline: none;
}

label.error {
    color: red;
    font-size: 12px;
    position: absolute;
    bottom: -20px;
}

.grecaptcha-badge {
    display: none !important;
}

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

:active,
:hover,
:focus {
    outline: 0 !important;
    outline-offset: 0;
}


.btn2 {
    position: relative;
    display: inline-block;
    width: auto;
    height: auto;
    background-color: transparent;
    border: none;
    cursor: pointer;
    margin: 0px 25px 15px;
    min-width: 150px;
    margin: 0;
    padding: 0;
}

.btn2 span {
    position: relative;
    display: inline-block;
    text-transform: uppercase;
    top: 0;
    left: 0;
    width: 100%;
    padding: 10px 20px;
    transition: 0.3s;
    color: #0A0A0A;
    text-align: center;
    font-family: "termina", sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 13px;
    font-weight: 600;
    line-height: 30px;
    letter-spacing: 3.9px;
    @media (max-width: 991px) {
        text-align: center;
        /* btn - MOBILE */
        font-size: 10px;
        font-style: normal;
        font-weight: 600;
        line-height: 21px; /* 210% */
        letter-spacing: 3px;
    }
}

/*--- btn-4 ---*/
.btn2-4 span:hover {
    color: #CC9C4A;
}

.btn2-4::before,
.btn2-4::after {
    width: 15%;
    height: 2px;
    background-color: #CC9C4A;
    z-index: 2;
    position: absolute;
    content: "";
}

/* 18. hover-border-8 */
.btn2.hover-border-8::before,
.btn2.hover-border-8::after {
    bottom: 0;
    width: 55%;
    transition: width 0.2s 0.35s ease-out;
}

.btn2.hover-border-8::before {
    left: 0;
    right: auto;

}

.btn2.hover-border-8::after {
    left: 0;
    right: auto;
}

.btn2.hover-border-8:hover::before {
    width: 100%;
    transition: width 0.2s ease-in;
}

.btn2.hover-border-8:hover::after {
    width: 100%;
    transition: width 0.1s ease-in;
}

.btn2.hover-border-8 span::before,
.btn2.hover-border-8 span::after {
    width: 0%;
    height: 0%;
    bottom: 0;
    background: transparent;
    opacity: 0;
    z-index: 2;
    position: absolute;
    content: "";
}

.btn2.hover-border-8 span::before {
    left: 0%;
    border-left: 2px solid #CC9C4A;
    transition: height 0.25s ease-in, opacity 0s 0.35s;
}

.btn2.hover-border-8 span:hover::before {
    height: 96%;
    opacity: 1;
    transition: height 0.25s 0.2s ease-out, opacity 0s 0.2s;
}

.btn2.hover-border-8 span::after {
    right: 0%;
    border-right: 2px solid #CC9C4A;
    border-top: 2px solid #CC9C4A;
    transition: width 0.2s ease-in, height 0.15s 0.2s linear, opacity 0s 0.35s;
}

.btn2.hover-border-8 span:hover::after {
    width: 99%;
    height: 96%;
    opacity: 1;
    transition: height 0.15s 0.1s linear, width 0.2s 0.25s linear, opacity 0s 0.1s;
}

.description{
    font-family: "termina", sans-serif !important;
    font-weight: 400 !important;
    font-style: normal !important;
}

.reveal-text {
    visibility: hidden;
}

.line {
    position: relative;
    overflow: hidden;
    display: flex;
    justify-content: center;
}
