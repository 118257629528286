#breadcrumb {
    ul {
        display: flex;
        padding-left: 14px;
        gap: 83px;
        margin-bottom: 118px;

        @media(max-width: 991px){
            margin-bottom: 56px;
        }

        li {
            position: relative;

            &::after {
                position: absolute;
                right: -50px;
                top: -2px;
                width: 24px;
                height: 24px;
                content: ' ';
                background: url('../img/breadcrumb/seta-breadcrumb.png') center center no-repeat;
            }

            &:last-child::after {
                display: none;
            }

            a {
                display: inline-block;
                text-decoration: none;
                font-family: "termina", sans-serif;
                font-weight: 400;
                font-style: normal;
                font-size: 12px;
                letter-spacing: 4.8px;
                color: #CC9C4A;
                text-transform: uppercase;

                @media(max-width: 991px){
                    font-size: 10px;
                }

                &.active {
                    font-family: "termina", sans-serif;
                    font-weight: 600;
                    font-style: normal;
                }
            }
        }
    }
}
