* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h5,
p {
  font-weight: normal;
  margin: 0;
}

:focus,
button:focus,
a:focus {
  outline: 0;
}

ol,
ul {
  list-style: none;
  padding: 0;
}

li {
  list-style: none;
}

a {
  color: inherit;
}
a:focus, a:hover {
  color: inherit;
  text-decoration: none;
  outline: 0;
}

form {
  margin: 0;
}

input:focus,
textarea:focus,
select:focus {
  outline: 0;
  box-shadow: 0 0 0 0;
}

button {
  border: none;
  background-color: transparent;
  padding: 0;
}
button:focus, button:hover {
  color: inherit;
  text-decoration: none;
  outline: 0;
}

@custom-media --desktop_big (width >= 1920px)

@custom-media --desktop (width <= 1399px)

@custom-media --desktop_min (width <= 1199px)

@custom-media --tablet_big (width <= 999px)

@custom-media --tablet (width <= 799px)

@custom-media --mobile_big (width <= 599px)

@custom-media --mobile (width <= 499px);
/*  */
/*------------- Hover background gradient --------------*/
@property --myColor1 {
  syntax: "<color>";
  initial-value: #FCA32D;
  inherits: false;
}
@property --myColor2 {
  syntax: "<color>";
  initial-value: #FF6196;
  inherits: false;
}
.button-hover-linearGradient {
  background-image: linear-gradient(105deg, var(--myColor1) 7%, var(--myColor2) 78%);
  transition: --myColor1 0.7s, --myColor2 0.7s;
}
.button-hover-linearGradient:hover {
  --myColor1: #ff933c;
  --myColor2: #ff933c;
}

.button-hover-linearGradient-others-browsers {
  background-image: linear-gradient(105deg, #ff933c 7%, #ff6196 78%);
  background-image: -o-linear-gradient(105deg, #ff933c 7%, #ff6196 78%);
  background-image: -moz-linear-gradient(105deg, #ff933c 7%, #ff6196 78%);
}

/*@font-face {
	font-family: 'Branding-Medium';
	src: url('../fonts/Branding/Branding-Medium.woff2') format('woff2'),
	url('../fonts/Branding/Branding-Medium.woff') format('woff'),
	url('../fonts/Branding/Branding-Medium.eot') format('eot'),
	url('../fonts/Branding/Branding-Medium.svg') format('svg');
}*/
@font-face {
  font-family: "Termina Test";
  src: url("../fonts/termina/Termina-Bold.woff2") format("woff2"), url("../fonts/termina/Termina-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Termina Test";
  src: url("../fonts/termina/Termina-Heavy.woff2") format("woff2"), url("../fonts/termina/Termina-Heavy.woff") format("woff");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Termina Test Extra";
  src: url("../fonts/termina/Termina-ExtraLight.woff2") format("woff2"), url("../fonts/termina/Termina-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Termina Test";
  src: url("../fonts/termina/Termina-Light.woff2") format("woff2"), url("../fonts/termina/Termina-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Termina Test Demi";
  src: url("../fonts/termina/Termina-Demi.woff2") format("woff2"), url("../fonts/termina/Termina-Demi.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Termina Test";
  src: url("../fonts/termina/Termina-Black.woff2") format("woff2"), url("../fonts/termina/Termina-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Termina Test";
  src: url("../fonts/termina/Termina-Regular.woff2") format("woff2"), url("../fonts/termina/Termina-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Termina Test";
  src: url("../fonts/termina/Termina-Medium.woff2") format("woff2"), url("../fonts/termina/Termina-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Termina Test";
  src: url("../fonts/termina/Termina-Thin.woff2") format("woff2"), url("../fonts/termina/Termina-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}
html {
  font-size: 62.5%;
}

.form-group {
  position: relative;
  margin: 0;
}

input.error,
textarea.error,
select.error {
  background-color: #fce4e4;
  border: 1px solid #cc0033;
  outline: none;
}

label.error {
  color: red;
  font-size: 12px;
  position: absolute;
  bottom: -20px;
}

.grecaptcha-badge {
  display: none !important;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

:active,
:hover,
:focus {
  outline: 0 !important;
  outline-offset: 0;
}

.btn2 {
  position: relative;
  display: inline-block;
  width: auto;
  height: auto;
  background-color: transparent;
  border: none;
  cursor: pointer;
  margin: 0px 25px 15px;
  min-width: 150px;
  margin: 0;
  padding: 0;
}

.btn2 span {
  position: relative;
  display: inline-block;
  text-transform: uppercase;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 20px;
  transition: 0.3s;
  color: #0A0A0A;
  text-align: center;
  font-family: "termina", sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 13px;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 3.9px;
}
@media (max-width: 991px) {
  .btn2 span {
    text-align: center;
    /* btn - MOBILE */
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    /* 210% */
    letter-spacing: 3px;
  }
}

/*--- btn-4 ---*/
.btn2-4 span:hover {
  color: #CC9C4A;
}

.btn2-4::before,
.btn2-4::after {
  width: 15%;
  height: 2px;
  background-color: #CC9C4A;
  z-index: 2;
  position: absolute;
  content: "";
}

/* 18. hover-border-8 */
.btn2.hover-border-8::before,
.btn2.hover-border-8::after {
  bottom: 0;
  width: 55%;
  transition: width 0.2s 0.35s ease-out;
}

.btn2.hover-border-8::before {
  left: 0;
  right: auto;
}

.btn2.hover-border-8::after {
  left: 0;
  right: auto;
}

.btn2.hover-border-8:hover::before {
  width: 100%;
  transition: width 0.2s ease-in;
}

.btn2.hover-border-8:hover::after {
  width: 100%;
  transition: width 0.1s ease-in;
}

.btn2.hover-border-8 span::before,
.btn2.hover-border-8 span::after {
  width: 0%;
  height: 0%;
  bottom: 0;
  background: transparent;
  opacity: 0;
  z-index: 2;
  position: absolute;
  content: "";
}

.btn2.hover-border-8 span::before {
  left: 0%;
  border-left: 2px solid #CC9C4A;
  transition: height 0.25s ease-in, opacity 0s 0.35s;
}

.btn2.hover-border-8 span:hover::before {
  height: 96%;
  opacity: 1;
  transition: height 0.25s 0.2s ease-out, opacity 0s 0.2s;
}

.btn2.hover-border-8 span::after {
  right: 0%;
  border-right: 2px solid #CC9C4A;
  border-top: 2px solid #CC9C4A;
  transition: width 0.2s ease-in, height 0.15s 0.2s linear, opacity 0s 0.35s;
}

.btn2.hover-border-8 span:hover::after {
  width: 99%;
  height: 96%;
  opacity: 1;
  transition: height 0.15s 0.1s linear, width 0.2s 0.25s linear, opacity 0s 0.1s;
}

.description {
  font-family: "termina", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
}

.reveal-text {
  visibility: hidden;
}

.line {
  position: relative;
  overflow: hidden;
  display: flex;
  justify-content: center;
}

/*
    SUMMARY
        0 - Set Transitions
        1 - Hovers
        2 - Enter Animations
        3 - Keyframes

*/
img.zoom--hover,
img.up--hover,
img.arrow--hover {
  transition: 0.4s;
}

img.zoom--hover:hover {
  transform: scale(1.05);
}

img.up--hover:hover {
  transform: translateY(-30px);
}

.double {
  display: grid !important;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.double__img {
  grid-area: 1/1/-1/-1;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: cover;
  width: 100%;
  height: 100%;
  position: relative;
}

.enter-image-style {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
  object-fit: cover;
}

a:hover img.arrow--hover,
button:hover img.arrow--hover {
  transform: translateX(10px);
}

.animated-element[data-animation] {
  opacity: 1 !important;
  transform: translate(0, 0) !important;
}

.scroll-down-animation {
  animation-name: scrollDown;
  animation-iteration-count: infinite;
  animation-duration: 3s;
}

[data-animation] {
  opacity: 0 !important;
  transition: 1s;
}

[data-animation=right] {
  transform: translateX(-50px);
}

[data-animation=left] {
  transform: translateX(50px);
}
@media (max-width: 999px) {
  [data-animation=left] {
    transform: translateX(-50px) !important;
  }
}

[data-animation=up] {
  transform: translateY(50px);
}

[data-animation=down] {
  transform: translateY(-50px);
}

[data-sequencial] [data-sequencial-stagger] {
  opacity: 0;
}
[data-sequencial] [data-sequencial-stagger=right] {
  transform: translateX(-50px);
}
[data-sequencial] [data-sequencial-stagger=left] {
  transform: translateX(50px);
}
[data-sequencial] [data-sequencial-stagger=up] {
  transform: translateY(50px);
}
[data-sequencial] [data-sequencial-stagger=down] {
  transform: translateY(50px);
}

[data-animation-banner] {
  opacity: 0 !important;
  transition: 1s;
}
[data-animation-banner].animated-element {
  opacity: 1 !important;
  transform: translate(0, 0) !important;
}

body:not(.no-js) .reveal {
  position: relative;
  overflow: hidden;
}

body:not(.no-js) .image-wrap {
  transition: 1s ease-out;
  transition-delay: 0.2s;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
  visibility: hidden;
}

body:not(.no-js) .image-wrap img {
  transform: scale(1.3);
  transition: 2s ease-out;
  position: relative !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

body:not(.no-js) .image-wrap .enter-image-style {
  transform: scale(1.3);
  transition: 2s ease-out;
  position: relative !important;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

body:not(.no-js) .animating .image-wrap {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  visibility: visible;
  transform: skewY(0);
}

body:not(.no-js) .animating img {
  transform: scale(1);
  transition: 4s ease-out;
}

body:not(.no-js) .animating .enter-image-style {
  transform: scale(1);
  transition: 4s ease-out;
}

@keyframes scrollDown {
  0% {
    transform: translate(13.062px, 20.843px);
  }
  50% {
    transform: translate(13.062px, 9.843px);
  }
  100% {
    transform: translate(13.062px, 20.843px);
  }
}
.scroll {
  width: 100%;
}

.text-marquee {
  position: relative;
  display: flex;
  align-items: center;
  overflow-x: hidden;
  z-index: 50;
}
.text-marquee .text-single {
  position: relative;
  white-space: nowrap;
  padding: 0;
  margin: 0;
  will-change: transform;
  overflow: hidden;
}
.text-marquee .text {
  padding: 0;
  display: inline-block;
  margin-right: 2rem;
  font-size: 90px;
  line-height: 1;
  color: #CC9C4A;
  font-weight: 500;
  font-family: "Playfair";
  text-align: left;
}
@media (max-width: 999px) {
  .text-marquee .text {
    color: var(--accent-color, #CC9C4A);
    text-align: center;
    font-size: 63px;
    font-style: normal;
    font-weight: 500;
    line-height: 1;
  }
}

.preloader {
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.logo-header {
  position: absolute;
  left: 18px;
  top: 27px;
  z-index: 999;
}
.logo-header.menu-logo {
  left: 126px;
  top: 42px;
}
@media (max-width: 991px) {
  .logo-header.menu-logo {
    left: 15px;
    top: 52px;
  }
}
@media (max-width: 991px) {
  .logo-header {
    left: -1px;
    top: 36px;
  }
  .logo-header img {
    max-width: 110px;
  }
}

.contato-fix {
  position: absolute;
  left: 126px;
  bottom: 60px;
}
.contato-fix span {
  color: #fff;
}
@media (max-width: 991px) {
  .contato-fix {
    left: 15px;
    bottom: 30px;
  }
}

.redes-fix {
  position: absolute;
  right: 15vw;
  bottom: 60px;
  display: flex;
  gap: 18px;
}
.redes-fix .redes-icon {
  width: 41px;
  height: 41px;
  border: 1px solid #CC9C4A;
  transition: all 0.1s ease-in-out;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.redes-fix .redes-icon path {
  transition: all 0.1s ease-in-out;
}
.redes-fix .redes-icon:hover {
  background-color: #CC9C4A;
}
.redes-fix .redes-icon:hover path {
  fill: #fff;
}
@media (max-width: 991px) {
  .redes-fix {
    right: 15px;
    bottom: 30px;
  }
}

#nav-container {
  margin-left: auto;
  position: relative;
}

#menu-icon {
  position: fixed;
  right: 15vw;
  top: 40px;
  padding: 18px;
  border: solid 1px #CC9C4A;
  border-radius: 37px;
  z-index: 999;
  transition: all 0.2s ease-in-out;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.1294117647);
}
#menu-icon:hover {
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.1294117647);
}
@media (max-width: 991px) {
  #menu-icon {
    right: 15px;
  }
}

#nav-toggle {
  display: block;
  width: 32px;
  height: 32px;
  z-index: 3;
  cursor: pointer;
  transform: rotate(0deg);
}

#nav-toggle span {
  background-color: black;
  width: 100%;
  height: 2px;
  border-radius: 1px;
  display: block;
  position: absolute;
  left: 0;
  content: "";
  transition: 0.5s ease-in-out;
}

#nav-toggle span:nth-child(1) {
  top: 4px;
  transform-origin: left center;
}

#nav-toggle span:nth-child(2) {
  top: 14px;
  transform-origin: left center;
}

#nav-toggle span:nth-child(3) {
  top: 24px;
  transform-origin: left center;
}

#nav-toggle.open span:nth-child(1) {
  transform: rotate(45deg);
  top: 3px;
  left: 4px;
}

#nav-toggle.open span:nth-child(2) {
  width: 0%;
  opacity: 0;
}

#nav-toggle.open span:nth-child(3) {
  transform: rotate(-45deg);
  top: 25px;
  left: 4px;
}

#nav-overlay {
  position: absolute;
  top: 16px;
  left: 150%;
  z-index: -1;
  background: rgba(0, 0, 0, 0.8);
  border-radius: 50%;
  transition: 1s;
  transform: scale3d(0, 0, 0);
}

#nav-overlay.open {
  transform: scale3d(1, 1, 1);
  z-index: 2;
}

#nav-fullscreen {
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  transition: ease-in-out 0.5s;
  transition-delay: 0.15s;
  visibility: hidden;
  opacity: 0;
}

#nav-fullscreen.open {
  visibility: visible;
  opacity: 1;
  transition: ease-in-out 0.5s;
  transition-delay: 0.25s;
  z-index: 999;
}
@media (max-width: 991px) {
  #nav-fullscreen.open {
    height: 100%;
    overflow: auto;
  }
}

html,
body {
  height: 100%;
}

body {
  overflow-x: hidden;
}

header {
  display: flex;
  padding: 16px;
  width: 100%;
  height: 150px;
  overflow: hidden;
  position: absolute;
}
header.heigh-all {
  height: 100vh;
}

#nav-overlay {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

#nav-toggle span {
  background-color: #CC9C4A;
}

#nav-toggle.yellow span {
  background-color: #CC9C4A;
}

#nav-fullscreen {
  display: flex;
  align-items: center;
  justify-content: center;
}

#wrap {
  margin-left: 182px;
  margin-top: 84px;
}
#wrap.d-none {
  display: none;
}
@media (max-width: 991px) {
  #wrap {
    margin-left: 0px;
    margin-top: 100px;
  }
}

.image {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: 50% 50%;
  filter: brightness(0.5);
}

.container > ul > li:first-child .image {
  opacity: 1;
  visibility: visible;
}

.link {
  color: #FFF;
  font-family: Playfair;
  font-size: 55px;
  font-style: normal;
  font-weight: 500;
  line-height: 75px;
  text-decoration: none;
  display: block;
  transition: all 0.5s ease-in;
}
@media (max-width: 991px) {
  .link {
    font-size: 38px;
    line-height: 52px;
  }
}
.link:hover, .link.active {
  transition: all 0.5s ease-in;
  color: #CC9C4A;
}

.container-item {
  position: relative;
}
.container-item::after {
  position: absolute;
  left: -69px;
  content: " ";
  width: 0;
  height: 1px;
  background-color: #CC9C4A;
  transition: all 0.5s ease-in;
}

.container-item.active {
  position: relative;
  transition: all 0.5s ease-in;
  width: fit-content;
}
.container-item.active::after {
  width: calc(100% + 69px);
}

.container-item.active .link + .image {
  opacity: 1;
  visibility: visible;
  animation: fadein 1.8s;
  animation-timing-function: cubic-bezier(0, 0.28, 0.435, 1.06);
}

.container-item.active .link {
  opacity: 1;
}

@keyframes fadein {
  0% {
    transform: scale(1.07);
  }
  100% {
    transform: scale(1);
  }
}
#footer {
  padding-top: 74px;
  position: relative;
  z-index: 9;
  background: #fff;
}
@media (min-width: 1320px) {
  #footer .container {
    max-width: 1320px;
    margin: 0 auto;
  }
}
#footer .linha-redes-logo {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#footer .redes {
  gap: 34px;
  align-items: center;
  padding-right: 15.5rem;
  padding-bottom: 3rem;
  display: flex;
}
@media (max-width: 991px) {
  #footer .redes {
    gap: 14px;
    padding-right: 0;
    padding: 0 20px;
  }
}
#footer .redes a {
  border: 1px solid #CC9C4A;
  border-radius: 50%;
  transition: all 0.3s ease-in-out;
}
#footer .redes a:hover {
  background-color: #CC9C4A;
}
#footer .redes a:hover svg {
  filter: brightness(0) invert(1);
}
#footer .redes .insta,
#footer .redes .yt {
  padding: 0.5rem;
}
#footer .grid-footer {
  display: grid;
  grid-template-columns: 3fr 2fr 1fr;
  margin-top: 71px;
  margin-bottom: 170px;
  padding-left: 1rem;
}
@media (max-width: 991px) {
  #footer .grid-footer {
    grid-template-columns: 1fr;
    margin-bottom: 80px;
  }
}
#footer .grid-footer .news-footer h2 {
  color: #0A0A0A;
  font-family: "Playfair";
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  /* 87.5% */
}
@media (max-width: 991px) {
  #footer .grid-footer .news-footer h2 {
    margin-top: 65px;
  }
}
#footer .grid-footer .news-footer p {
  color: #0A0A0A;
  font-family: "Playfair";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  /* 125% */
  max-width: 65%;
  margin-top: 28px;
  margin-bottom: 29px;
}
#footer .grid-footer .news-footer .btn-find {
  cursor: pointer;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 230.769% */
  letter-spacing: 3.9px;
  color: #0A0A0A;
  text-transform: uppercase;
  text-decoration: none;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 1.5rem;
  position: relative;
  font-family: "termina", sans-serif;
  width: fit-content;
  left: 18%;
}
#footer .grid-footer .news-footer .btn-find:hover::after {
  width: 100%;
}
#footer .grid-footer .news-footer .btn-find::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40%;
  height: 1px;
  background: #CC9C4A;
  transition: all 0.2s ease-in;
}
#footer .grid-footer .news-footer input {
  width: 65%;
  border: none;
  border-bottom: 2px solid #CC9C4A;
  height: 50px;
  margin-bottom: 43px;
  font-size: 20px;
}
#footer .grid-footer .news-footer input::placeholder {
  color: #0A0A0A;
  font-family: "termina", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  /* 169.231% */
}
#footer .grid-footer .adress-ctt h4 {
  color: #CC9C4A;
  font-family: "Playfair";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  /* 175% */
}
@media (max-width: 991px) {
  #footer .grid-footer .adress-ctt h4 {
    margin-top: 32px;
  }
}
#footer .grid-footer .adress-ctt p, #footer .grid-footer .adress-ctt a {
  color: #0A0A0A;
  font-family: "termina", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  /* 230.769% */
  max-width: 75%;
  text-decoration: none;
}
#footer .grid-footer .site-btn {
  display: flex;
  flex-direction: column;
  padding-left: 3rem;
}
@media (max-width: 991px) {
  #footer .grid-footer .site-btn {
    padding-left: 0;
  }
}
#footer .grid-footer .site-btn h4 {
  color: #CC9C4A;
  font-family: "Playfair";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  /* 175% */
}
#footer .grid-footer .site-btn a {
  color: #0A0A0A;
  font-family: "termina", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 37px;
  /* 284.615% */
  text-decoration: none;
  transition: all 0.2s ease-in;
}
#footer .grid-footer .site-btn a:hover {
  color: #CC9C4A;
}
#footer .rodape {
  align-items: center;
  display: flex;
  height: 91.5px;
  background: rgba(217, 217, 217, 0.62);
}
#footer .rodape .container {
  max-width: 1300px;
}
#footer .rodape .linha-rodape {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
#footer .rodape .linha-rodape p {
  color: rgba(10, 10, 10, 0.49);
  font-family: "termina", sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  /* 230.769% */
}
@media (max-width: 991px) {
  #footer .rodape .linha-rodape p {
    font-size: 10px;
  }
}

html.lenis {
  height: auto;
}

.lenis.lenis-smooth {
  scroll-behavior: auto;
}

.lenis.lenis-smooth [data-lenis-prevent] {
  overscroll-behavior: contain;
}

.lenis.lenis-stopped {
  overflow: hidden;
}

.lenis.lenis-scrolling iframe {
  pointer-events: none;
}

.gscrollbar-fixer {
  margin: 0 !important;
  padding: 0 !important;
}

html, body {
  scrollbar-width: auto;
  scrollbar-color: #fff #000;
}
html::-webkit-scrollbar, body::-webkit-scrollbar {
  width: 5px;
}
html::-webkit-scrollbar-track, body::-webkit-scrollbar-track {
  background: #fff;
}
html::-webkit-scrollbar-thumb, body::-webkit-scrollbar-thumb {
  background-color: #000;
  border-radius: 0;
  border: 3px solid #000;
}

#modal-zoom-images {
  position: fixed;
  inset: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  transform: scale(0);
  opacity: 0;
  transition: 0.4s;
  z-index: -100;
  display: flex;
  align-items: center;
  justify-content: center;
}
#modal-zoom-images.activate-modal {
  opacity: 1;
  transform: scale(1);
  z-index: 10000;
}
#modal-zoom-images #btn-close__zoom-modal {
  position: absolute;
  right: 25px;
  top: 25px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media (max-width: 599px) {
  #modal-zoom-images #btn-close__zoom-modal {
    top: 10px;
    right: 10px;
  }
}
#modal-zoom-images #btn-close__zoom-modal i {
  font-size: 25px;
  color: rgb(226, 226, 226);
}
#modal-zoom-images #image__zoom-modal {
  width: 80vw;
  height: 80vh;
  object-fit: contain;
}
@media (max-width: 599px) {
  #modal-zoom-images #image__zoom-modal {
    width: 90vw;
  }
}

.images--zoom {
  cursor: pointer;
}

.error-message-forms {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  display: none;
  opacity: 1;
}
.error-message-forms.open-modal {
  display: flex;
}
.error-message-forms--container {
  position: absolute;
  transform: translateY(-65%) translateX(-50%);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  top: 50%;
  left: 50%;
  width: 450px;
  padding: 30px 50px;
  gap: 20px;
}
.error-message-forms--container #btn--close-error-message {
  position: absolute;
  top: 2vw;
  right: 2vw;
}
.error-message-forms--container #btn--close-error-message ion-icon {
  font-size: 3.4rem;
  color: 900;
}
.error-message-forms--container .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: #ffffff;
  font-size: 4.5rem;
  color: white;
}
.error-message-forms--container .description {
  font-size: 2rem;
  line-height: 1.7;
  color: 900;
  font-weight: 400;
  font-family: "";
  text-align: center;
  font-family: "Playfair" !important;
}
.error-message-forms--container .confirm {
  font-size: 1.6rem;
  line-height: 1;
  color: #ffffff;
  font-weight: 700;
  font-family: "";
  text-align: center;
  padding: 20px 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-family: "Playfair";
}
@media (max-width: 799px) {
  .error-message-forms--container .confirm {
    margin-top: 40px;
  }
}
@media (max-width: 799px) {
  .error-message-forms--container {
    width: 95%;
    padding-inline: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    gap: 30px;
  }
  .error-message-forms--container #btn--close-error-message img {
    width: 20px;
  }
  .error-message-forms--container .icon {
    width: 65px;
    height: 65px;
  }
  .error-message-forms--container .description {
    font-size: 1.4rem;
    line-height: 20px;
    font-family: "Playfair";
  }
  .error-message-forms--container .confirm {
    margin-top: 0px;
    width: 150px;
    height: 50px;
  }
}

.success-message-forms {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  display: none;
  opacity: 1;
}
.success-message-forms.open-modal {
  display: flex;
}
.success-message-forms--container {
  position: absolute;
  transform: translateY(-65%) translateX(-50%);
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border: 2px solid #CC9C4A;
  top: 50%;
  left: 50%;
  width: 500px;
  padding: 30px 50px;
  gap: 20px;
}
.success-message-forms--container #btn--close-success-message {
  position: absolute;
  top: 2vw;
  right: 2vw;
}
.success-message-forms--container #btn--close-success-message ion-icon {
  font-size: 3.4rem;
  color: 900;
}
.success-message-forms--container .icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 5vw;
  aspect-ratio: 1;
  border-radius: 50%;
  background-color: #ffffff;
  font-size: 4.5rem;
  color: white;
}
.success-message-forms--container .description {
  font-size: 2rem;
  line-height: 1.7;
  color: 900;
  font-weight: 400;
  font-family: "";
  text-align: center;
  font-family: "Playfair";
}
.success-message-forms--container .confirm {
  font-size: 1.6rem;
  line-height: 1;
  color: #ffffff;
  font-weight: 700;
  font-family: "";
  text-align: center;
  padding: 20px 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  font-family: "Playfair";
}
@media (max-width: 799px) {
  .success-message-forms--container .confirm {
    margin-top: 40px;
  }
}
@media (max-width: 799px) {
  .success-message-forms--container {
    width: 95%;
    padding-inline: 20px;
    padding-top: 40px;
    padding-bottom: 40px;
    gap: 30px;
  }
  .success-message-forms--container #btn--close-success-message img {
    width: 20px;
  }
  .success-message-forms--container .icon {
    width: 65px;
    height: 65px;
  }
  .success-message-forms--container .description {
    font-size: 1.4rem;
    line-height: 20px;
    font-family: "Playfair";
  }
  .success-message-forms--container .confirm {
    margin-top: 0px;
    width: 150px;
    height: 50px;
  }
}

.loading-forms {
  width: 100%;
  height: 100%;
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 10000;
  display: none;
  opacity: 1;
}
.loading-forms.open-modal {
  display: flex;
}
.loading-forms--container {
  position: absolute;
  transform: translateY(-65%) translateX(-50%);
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  left: 50%;
  width: 30vw;
  height: 30vh;
}
.loading-forms .custom-loader {
  width: 5vw;
  height: 5vw;
  border-radius: 50%;
  background: conic-gradient(rgba(0, 0, 0, 0) 10%, 900);
  -webkit-mask: radial-gradient(farthest-side, rgba(0, 0, 0, 0) calc(100% - 8px), #000 0);
  animation: s3 1s infinite linear;
}
@media (max-width: 799px) {
  .loading-forms--container {
    width: 95%;
    padding: 0 !important;
    height: 200px;
  }
  .loading-forms .custom-loader {
    width: 40px;
    height: 40px;
  }
}

#breadcrumb ul {
  display: flex;
  padding-left: 14px;
  gap: 83px;
  margin-bottom: 118px;
}
@media (max-width: 991px) {
  #breadcrumb ul {
    margin-bottom: 56px;
  }
}
#breadcrumb ul li {
  position: relative;
}
#breadcrumb ul li::after {
  position: absolute;
  right: -50px;
  top: -2px;
  width: 24px;
  height: 24px;
  content: " ";
  background: url("../img/breadcrumb/seta-breadcrumb.png") center center no-repeat;
}
#breadcrumb ul li:last-child::after {
  display: none;
}
#breadcrumb ul li a {
  display: inline-block;
  text-decoration: none;
  font-family: "termina", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  letter-spacing: 4.8px;
  color: #CC9C4A;
  text-transform: uppercase;
}
@media (max-width: 991px) {
  #breadcrumb ul li a {
    font-size: 10px;
  }
}
#breadcrumb ul li a.active {
  font-family: "termina", sans-serif;
  font-weight: 600;
  font-style: normal;
}

#privacy {
  min-height: 85dvh;
  padding-top: 25vh;
}
#privacy .title {
  color: var(--Black, #0A0A0A);
  text-align: start;
  font-family: "Playfair";
  font-size: 45px;
  font-style: normal;
  font-weight: 500;
  line-height: 45px;
  margin-bottom: 30px;
}
#privacy .subtitle {
  color: #004054;
  text-align: start;
  font-family: "Playfair";
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 30px;
  padding-left: 5%;
}
#privacy .text {
  color: #000;
  text-align: start;
  font-family: "Playfair";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  padding-left: 5%;
}

#page404 {
  min-height: 85dvh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 150px;
  background: url("../img/purpose/bg-proposito2.webp") bottom center no-repeat;
}
#page404 img {
  aspect-ratio: 1/1;
  max-width: 100%;
}
#page404 h2 {
  color: #004054;
  text-align: start;
  font-family: "Playfair";
  font-size: 45px;
  font-style: normal;
  font-weight: 500;
  line-height: 45px;
  margin-bottom: 30px;
}
#page404 .text {
  color: #000;
  text-align: start;
  font-family: "Playfair";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
#page404 .line {
  justify-content: flex-start;
}

#home {
  --progress: 0;
}
@media (max-width: 991px) {
  #home {
    overflow-x: hidden;
  }
}
#home .banner-gif {
  height: 100dvh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  gap: 30px;
  top: -5px;
  cursor: none;
  /*animated scroll arrow animation*/
}
@media (max-width: 991px) {
  #home .banner-gif {
    cursor: auto;
  }
}
#home .banner-gif::after {
  content: "";
  position: absolute;
  bottom: -80px;
  left: 50%;
  height: 160px;
  width: 1px;
  background: #CC9C4A;
}
#home .banner-gif::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100.5%;
  background: rgba(0, 0, 0, 0.2431372549);
}
#home .banner-gif .btn2 {
  margin-bottom: 4rem;
}
#home .banner-gif .destaque-video {
  position: absolute;
  z-index: -1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#home .banner-gif .text-destaque {
  font-family: "Playfair Display", serif;
  color: #fff;
  font-size: 80px;
  font-style: normal;
  font-weight: 300;
  line-height: 90px;
  /* 81.25% */
  text-align: center;
}
@media (max-width: 991px) {
  #home .banner-gif .text-destaque {
    color: #FFF;
    text-align: center;
    /* H1 - Title MOBILE */
    font-family: "Playfair";
    font-size: 56px;
    font-style: normal;
    font-weight: 300;
    line-height: 50px;
    /* 89.286% */
  }
  #home .banner-gif .text-destaque br {
    display: none;
  }
}
#home .banner-gif .btn-find {
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 230.769% */
  letter-spacing: 3.9px;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 1.5rem;
  position: relative;
  font-family: "termina", sans-serif;
}
#home .banner-gif .btn-find:hover::after {
  width: 100%;
}
#home .banner-gif .btn-find::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40%;
  height: 1px;
  background: #CC9C4A;
  transition: all 0.2s ease-in;
}
#home .banner-gif .btn-down {
  cursor: pointer;
  position: absolute;
  bottom: 70px;
  left: 20vw;
}
#home .banner-gif .btn-down2 {
  cursor: pointer;
  position: absolute;
  width: 76px;
  height: 76px;
  bottom: 77px;
  left: 16.6vw;
  background: url("../img/seta.svg") center center no-repeat;
  background-size: contain;
  overflow: hidden;
}
@media (max-width: 991px) {
  #home .banner-gif .btn-down2 {
    width: 53px;
    height: 53px;
    bottom: 56px;
    left: 23px;
  }
}
#home .banner-gif .ca3-scroll-down-arrow {
  background-image: url("../img/seta-home.png");
  background-size: contain;
  background-repeat: no-repeat;
}
#home .banner-gif .ca3-scroll-down-link {
  cursor: pointer;
  height: 30px;
  width: 15px;
  position: absolute;
  left: 0;
  right: 0;
  top: 20px;
  margin: 0 auto;
  z-index: 100;
  -webkit-animation: ca3_fade_move_down 1s ease-in-out infinite;
  -moz-animation: ca3_fade_move_down 1s ease-in-out infinite;
  animation: ca3_fade_move_down 1s ease-in-out infinite;
}
@-webkit-keyframes ca3_fade_move_down {
  0% {
    -webkit-transform: translate(0, -20px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 20px);
    opacity: 0;
  }
}
@-moz-keyframes ca3_fade_move_down {
  0% {
    -moz-transform: translate(0, -20px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -moz-transform: translate(0, 20px);
    opacity: 0;
  }
}
@keyframes ca3_fade_move_down {
  0% {
    transform: translate(0, -20px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 20px);
    opacity: 0;
  }
}
#home .banner-gif .btn2 span {
  color: #ffffff;
}
@media (max-width: 991px) {
  #home .banner-gif .btn2 span {
    color: var(--white, #FFF);
    text-align: center;
    /* btn - MOBILE */
    font-family: "termina", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 600;
    line-height: 21px;
    /* 210% */
    letter-spacing: 3px;
  }
}
#home .banner-gif .btn2-4 span:hover {
  color: #CC9C4A;
}
#home .banner-gif .ball {
  width: 128px;
  height: 128px;
  position: fixed;
  top: 0;
  left: 0;
  border: 1px solid #CC9C4A;
  border-radius: 50%;
  pointer-events: none;
  z-index: 9999;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  background-color: rgba(0, 0, 0, 0.1294117647);
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}
@media (max-width: 991px) {
  #home .banner-gif .ball {
    display: none;
  }
}
#home .quem-somos {
  padding-top: 130px;
  background: #fff;
  padding-bottom: 78px;
}
@media (max-width: 991px) {
  #home .quem-somos {
    padding-top: 74px;
    padding-bottom: 0;
  }
}
#home .quem-somos .container {
  max-width: 1426px;
  margin: 0 auto;
}
@media (max-width: 1399px) {
  #home .quem-somos .container {
    max-width: 1280px;
  }
}
@media (max-width: 991px) {
  #home .quem-somos .container {
    padding: 0;
  }
}
#home .quem-somos .title-box {
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 80%;
  margin: 0 auto;
  padding-bottom: 130px;
  align-items: center;
}
@media (max-width: 991px) {
  #home .quem-somos .title-box {
    width: 100%;
    gap: 40px;
    padding-bottom: 87px;
  }
}
#home .quem-somos .btn-find {
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 230.769% */
  letter-spacing: 3.9px;
  color: #0A0A0A;
  text-transform: uppercase;
  text-decoration: none;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 1.5rem;
  position: relative;
  font-family: "termina", sans-serif;
  width: fit-content;
}
#home .quem-somos .btn-find:hover::after {
  width: 100%;
}
#home .quem-somos .btn-find::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40%;
  height: 1px;
  background: #CC9C4A;
  transition: all 0.2s ease-in;
}
#home .quem-somos .title {
  color: var(--Black, #0A0A0A);
  text-align: center;
  /* desktop/H4 - Subtitle */
  font-family: "Playfair";
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 35px;
  /* 66.667% */
  max-width: 65%;
}
#home .quem-somos .title strong {
  color: var(--accent-color, #CC9C4A);
}
@media (max-width: 991px) {
  #home .quem-somos .title {
    color: #0A0A0A;
    text-align: center;
    font-family: "Playfair";
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    /* 100% */
    max-width: 100%;
    padding-inline: 22px;
  }
  #home .quem-somos .title br {
    display: none;
  }
}
#home .quem-somos .grid-content {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 684px);
  gap: 50px;
  padding-top: 1rem;
}
@media (max-width: 1400px) {
  #home .quem-somos .grid-content {
    grid-template-columns: repeat(2, 500px);
    justify-content: center;
  }
}
@media (max-width: 1100px) {
  #home .quem-somos .grid-content {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
}
#home .quem-somos .grid-subtitle {
  color: var(--Black, #0A0A0A);
  text-align: center;
  font-family: "termina", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 250% */
  letter-spacing: 4.8px;
}
@media (max-width: 991px) {
  #home .quem-somos .grid-subtitle {
    color: var(--Black, #0A0A0A);
    text-align: center;
    /* Legend - MOBILE */
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    /* 210% */
    letter-spacing: 4px;
  }
}
#home .quem-somos .grid-title {
  color: #CC9C4A;
  text-align: center;
  /* desktop/H2 - Title */
  font-family: "Playfair";
  font-size: 45px;
  font-style: normal;
  font-weight: 500;
  line-height: 45px;
  margin-top: 30px;
}
@media (max-width: 991px) {
  #home .quem-somos .grid-title {
    color: #CC9C4A;
    text-align: center;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
    /* 43.75% */
    margin-bottom: 20px;
  }
}
#home .quem-somos .text {
  color: var(--Black, #0A0A0A);
  text-align: center;
  /* desktop/H6 - textBody */
  font-family: "Playfair";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  /* 150% */
  max-width: 80%;
  margin: 0 auto;
  margin-bottom: 90px;
  margin-top: 20px;
}
@media (max-width: 1400px) {
  #home .quem-somos .text br {
    display: none;
  }
}
@media (max-width: 991px) {
  #home .quem-somos .text {
    color: var(--Black, #0A0A0A);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    /* 150% */
    margin: 0;
    max-width: 100%;
    margin-bottom: 40px;
  }
}
@media (max-width: 991px) {
  #home .quem-somos .box-item {
    display: flex;
    flex-direction: column;
  }
  #home .quem-somos .box-item .slider-component {
    order: 3;
  }
}
#home .quem-somos .slider-component {
  aspect-ratio: 1.2;
  position: relative;
  margin-bottom: 73px;
}
#home .quem-somos .slider-component img {
  width: 100%;
  object-fit: cover;
}
#home .quem-somos .slider-component .progress-bar {
  width: 80%;
  height: 2px;
  background-color: #CC9C4A;
  border-radius: 2px;
  overflow: hidden;
  position: absolute;
  bottom: 30px;
  left: 10%;
  z-index: 9;
  transform: matrix(-1, 0, 0, -1, 0, 0);
}
#home .quem-somos .slider-component .inside-bar {
  transform-origin: left center;
  border-radius: 2px;
  background-color: #fff;
  height: 2px;
  transform: scaleX(var(--progress));
}
#home .quem-somos .content--image {
  display: flex;
  width: 100%;
  height: 548px;
  overflow: hidden;
  position: relative;
}
@media (max-width: 991px) {
  #home .quem-somos .content--image {
    height: 341px;
  }
}
#home .quem-somos .content--image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}
#home .quem-somos .enter-image-style {
  background-repeat: no-repeat;
  background-size: cover;
}
#home .video-gif {
  position: relative;
  padding-bottom: 120px;
}
#home .video-gif .btn-find {
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 230.769% */
  letter-spacing: 3.9px;
  color: #0A0A0A;
  text-transform: uppercase;
  text-decoration: none;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 1.5rem;
  position: relative;
  font-family: "termina", sans-serif;
  width: fit-content;
  margin: 0 auto;
}
#home .video-gif .btn-find:hover::after {
  width: 100%;
}
#home .video-gif .btn-find::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40%;
  height: 1px;
  background: #CC9C4A;
  transition: all 0.2s ease-in;
}
#home .video-gif .text-destaque {
  color: #000;
  text-align: center;
  font-family: "Playfair";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  max-width: 58%;
  margin: 0 auto;
  margin-bottom: 50px;
  margin-top: 118px;
}
@media (max-width: 991px) {
  #home .video-gif .text-destaque {
    margin-top: 80px;
    color: #000;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    /* 150% */
    max-width: 100%;
    padding-inline: 15px;
  }
}
#home .video-gif .img-gif {
  width: 100%;
  max-height: 630px;
  object-fit: cover;
}
#home .video-gif .text-marquee {
  position: absolute;
  top: 25%;
}
@media (max-width: 991px) {
  #home .video-gif .text-marquee {
    top: 18%;
  }
}
#home .video-gif .container {
  max-width: 1425px;
  margin: 0 auto;
}
#home .video-gif .content--image {
  display: flex;
  width: 100%;
  height: 630px;
  overflow: hidden;
  position: relative;
}
@media (max-width: 991px) {
  #home .video-gif .content--image {
    height: 275px;
  }
}
#home .video-gif .content--image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}
#home .video-gif .enter-image-style {
  background-repeat: no-repeat;
  background-size: cover;
}
#home .slider-vinhos {
  min-height: 100dvh;
  background-color: #003644;
  background-size: cover;
  display: flex;
  align-items: center;
  padding-top: 88px;
  padding-bottom: 73px;
}
#home .slider-vinhos .swiper-slide-active .bottle-destaque {
  opacity: 1;
  transform: translate(-36%, 8%);
}
@media (max-width: 991px) {
  #home .slider-vinhos .swiper-slide-active .bottle-destaque {
    transform: translate(0%, 0%) scale(0.8);
    gap: 32px;
  }
}
#home .slider-vinhos .swiper-slide-active .text-content {
  opacity: 1;
  transform: translate(0px, 0%);
}
#home .slider-vinhos .swiper-button-prev,
#home .slider-vinhos .swiper-rtl .swiper-button-next,
#home .slider-vinhos .swiper-button-next,
#home .slider-vinhos .swiper-rtl .swiper-button-prev {
  width: 120px;
  height: 120px;
}
@media (max-width: 1600px) {
  #home .slider-vinhos .swiper-button-prev,
#home .slider-vinhos .swiper-rtl .swiper-button-next,
#home .slider-vinhos .swiper-button-next,
#home .slider-vinhos .swiper-rtl .swiper-button-prev {
    transform: scale(0.8);
  }
}
@media (max-width: 1250px) {
  #home .slider-vinhos .swiper-button-prev,
#home .slider-vinhos .swiper-rtl .swiper-button-next,
#home .slider-vinhos .swiper-button-next,
#home .slider-vinhos .swiper-rtl .swiper-button-prev {
    transform: scale(0.7);
  }
}
@media (max-width: 1100px) {
  #home .slider-vinhos .swiper-button-prev,
#home .slider-vinhos .swiper-rtl .swiper-button-next,
#home .slider-vinhos .swiper-button-next,
#home .slider-vinhos .swiper-rtl .swiper-button-prev {
    transform: scale(1);
    width: 40px;
    height: 40px;
  }
}
#home .slider-vinhos .swiper-button-prev::after,
#home .slider-vinhos .swiper-rtl .swiper-button-next::after,
#home .slider-vinhos .swiper-button-next::after,
#home .slider-vinhos .swiper-rtl .swiper-button-prev::after {
  display: none;
}
#home .slider-vinhos .swiper-button-prev {
  left: 38px;
  top: 35%;
}
@media (max-width: 991px) {
  #home .slider-vinhos .swiper-button-prev {
    top: 75%;
    left: 15px;
  }
}
#home .slider-vinhos .swiper-button-next {
  right: 14px;
  top: 35%;
  filter: brightness(0) invert(1);
}
@media (max-width: 991px) {
  #home .slider-vinhos .swiper-button-next {
    top: 75%;
    right: 15px;
  }
}
#home .slider-vinhos .bottles-controll {
  max-width: 1320px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  margin-bottom: 165px;
  position: relative;
}
#home .slider-vinhos .bottles-controll::after {
  content: "";
  width: 80%;
  height: 2px;
  background-color: #fff;
  position: absolute;
  bottom: -40px;
}
@media (max-width: 991px) {
  #home .slider-vinhos .bottles-controll::after {
    display: none;
  }
}
@media (max-width: 991px) {
  #home .slider-vinhos .bottles-controll {
    max-width: 100%;
    width: 100%;
    overflow-x: scroll;
    height: 100%;
    overflow-y: hidden;
    margin-bottom: 0;
    justify-content: inherit;
    margin-bottom: 100px;
    padding-bottom: 30px;
    justify-content: space-between;
  }
}
#home .slider-vinhos .ball-vinhos {
  position: absolute;
  top: 5px;
  left: 0;
  width: 14px;
  height: 14px;
  background: #fff;
  border-radius: 50%;
}
#home .slider-vinhos .nav-controlls-bullet {
  display: flex;
  position: absolute;
  width: 79%;
  bottom: -49px;
  justify-content: space-between;
}
#home .slider-vinhos .nav-controlls-bullet .nav-bullet {
  height: 23px;
  width: 1px;
  background: #fff;
}
#home .slider-vinhos .nav-controlls-bullet .nav-bullet.active {
  height: 14px;
  width: 14px;
  background: transparent;
  border-radius: 50%;
  position: relative;
  align-items: center;
  justify-content: center;
  display: flex;
  bottom: -5px;
}
#home .slider-vinhos .nav-controlls-bullet .nav-bullet.active::after {
  content: "";
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: transparent;
  border: 1px solid #fff;
}
@media (max-width: 991px) {
  #home .slider-vinhos .nav-controlls-bullet {
    bottom: 0;
    width: 100%;
    max-width: 100%;
    gap: 112px;
    overflow-x: scroll;
    justify-content: space-around;
  }
  #home .slider-vinhos .nav-controlls-bullet::after {
    position: absolute;
    width: 100%;
    content: "";
    height: 2px;
    background-color: #fff;
    bottom: 10px;
    left: 0;
  }
}
#home .slider-vinhos .bottle-box {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
  cursor: pointer;
  transition: all 0.5s ease-in-out;
}
#home .slider-vinhos .bottle-box:hover {
  transform: scale(1.2);
  gap: 20px;
}
@media (max-width: 991px) {
  #home .slider-vinhos .bottle-box {
    gap: 20px;
  }
  #home .slider-vinhos .bottle-box:hover {
    transform: scale(1);
  }
}
#home .slider-vinhos .bottle-box img {
  max-height: 102px;
}
@media (max-width: 991px) {
  #home .slider-vinhos .bottle-box img {
    max-height: 71px;
  }
}
#home .slider-vinhos .bottle-box h4 {
  color: #fff;
  text-align: center;
  font-family: "Playfair";
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
@media (max-width: 991px) {
  #home .slider-vinhos .bottle-box h4 {
    font-size: 20px;
    line-height: 21px;
  }
}
#home .slider-vinhos .container {
  max-width: 90vw;
}
@media (max-width: 991px) {
  #home .slider-vinhos .container {
    max-width: 100%;
  }
}
#home .slider-vinhos .box-content {
  display: grid;
  grid-template-columns: 450px 450px;
  justify-items: center;
  justify-content: center;
  gap: 400px;
  width: 100%;
  margin: 0 auto;
  min-height: 75dvh;
}
@media (max-width: 1600px) {
  #home .slider-vinhos .box-content {
    transform: scale(0.8);
  }
}
@media (max-width: 1250px) {
  #home .slider-vinhos .box-content {
    transform: scale(0.7);
  }
}
@media (max-width: 1100px) {
  #home .slider-vinhos .box-content {
    display: flex;
    flex-direction: column;
    transform: scale(1);
    padding-inline: 7.5px;
    min-height: auto;
    gap: 0;
  }
}
#home .slider-vinhos .text-content {
  max-width: 450px;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  transform: translate(0px, -5%);
}
@media (max-width: 1100px) {
  #home .slider-vinhos .text-content {
    min-height: 400px;
  }
}
#home .slider-vinhos .title {
  color: #FFF;
  font-family: "Playfair";
  font-size: 45px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  margin-bottom: 56px;
  padding-left: 5rem;
}
@media (max-width: 1100px) {
  #home .slider-vinhos .title {
    color: #FFF;
    font-family: "Playfair";
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 32px;
    /* 100% */
    margin-bottom: 30px;
    padding-left: 0;
  }
}
#home .slider-vinhos .text {
  color: #FFF;
  font-family: "Playfair";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  padding-left: 26%;
  min-height: 250px;
}
@media (max-width: 1100px) {
  #home .slider-vinhos .text {
    color: #FFF;
    font-family: "Playfair";
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    padding-left: 0;
    min-height: auto;
    /* 150% */
  }
}
#home .slider-vinhos .text-small {
  color: #FFF;
  font-family: "termina", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  padding-left: 26%;
  position: relative;
  margin-top: 70px;
}
@media (max-width: 1100px) {
  #home .slider-vinhos .text-small {
    color: #FFF;
    font-family: "termina", sans-serif;
    font-size: 10px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    padding-left: 0;
    /* 210% */
  }
}
#home .slider-vinhos .text-small .line-color {
  background: #fff;
  position: absolute;
  left: -100%;
  width: 200%;
  height: 1px;
  top: -35px;
}
#home .slider-vinhos .bottle-destaque {
  position: relative;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 7rem;
  opacity: 0;
  transform: translate(-36%, 0%);
  display: flex;
}
#home .slider-vinhos .bottle-destaque h3 {
  color: #FFF;
  text-align: center;
  font-family: " Playfair";
  font-size: 108px;
  font-style: normal;
  font-weight: 500;
  line-height: 120px;
  transform-origin: center;
}
#home .slider-vinhos .bottle-destaque h3 span {
  position: relative;
}
@media (max-width: 990px) {
  #home .slider-vinhos .bottle-destaque h3 {
    font-size: 65px;
    line-height: 65px;
  }
}
#home .slider-vinhos .bottle-destaque img {
  position: absolute;
  top: -16%;
  aspect-ratio: 0.5;
  left: 13%;
  pointer-events: none;
  z-index: 99;
}
@media (max-width: 990px) {
  #home .slider-vinhos .bottle-destaque img {
    top: -8%;
    left: 27%;
    max-height: 335px;
  }
}
#home .slider-vinhos .btn-find {
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 230.769% */
  letter-spacing: 3.9px;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 1.5rem;
  position: relative;
  font-family: "Termina Test Demi";
  width: fit-content;
  margin: 0 auto;
  margin-top: 40px;
}
#home .slider-vinhos .btn-find:hover::after {
  width: 100%;
}
#home .slider-vinhos .btn-find::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40%;
  height: 1px;
  background: #CC9C4A;
  transition: all 0.2s ease-in;
}
#home .slider-vinhos .btn2 span {
  color: #ffffff;
}
#home .slider-vinhos .btn2-4 span:hover {
  color: #CC9C4A;
}
#home .banner-paralax {
  height: 743px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
#home .banner-paralax::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.3;
}
@media (max-width: 991px) {
  #home .banner-paralax {
    height: 520px;
  }
}
#home .banner-paralax .paralax-content {
  max-width: 730px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  position: relative;
  z-index: 9;
}
#home .banner-paralax .paralax-content .btn2 span {
  color: #ffffff;
}
#home .banner-paralax .paralax-content .btn2-4 span:hover {
  color: #CC9C4A;
}
#home .banner-paralax .title {
  color: #FFF;
  text-align: center;
  /* desktop/H1 - Title */
  font-family: Playfair;
  font-size: 80px;
  font-style: normal;
  font-weight: 300;
  line-height: 65px;
  /* 81.25% */
}
#home .banner-paralax .title strong {
  color: #CC9C4A;
}
@media (max-width: 991px) {
  #home .banner-paralax .title {
    color: #FFF;
    text-align: center;
    font-size: 56px;
    font-style: normal;
    font-weight: 300;
    line-height: 50px;
    /* 89.286% */
  }
}
#home .banner-paralax .text {
  color: #FFF;
  text-align: center;
  font-family: Playfair;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  /* 100% */
}
@media (max-width: 991px) {
  #home .banner-paralax .text {
    color: #FFF;
    text-align: center;
    font-size: 21px;
    font-style: normal;
    font-weight: 500;
    line-height: 21px;
    /* 100% */
  }
}
#home .banner-paralax .btn-find {
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 230.769% */
  letter-spacing: 3.9px;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 1.5rem;
  position: relative;
  font-family: "Termina Test Demi";
  width: fit-content;
  margin: 0 auto;
}
#home .banner-paralax .btn-find:hover::after {
  width: 100%;
}
#home .banner-paralax .btn-find::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40%;
  height: 1px;
  background: #CC9C4A;
  transition: all 0.2s ease-in;
}

#who {
  --progress: 0;
  padding: 248px 0 0 0;
}
#who h1 {
  font-family: "termina", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 38px;
  line-height: 57px;
  letter-spacing: 15.2px;
  margin-left: 12px;
  color: #CC9C4A;
}
#who h1 .line {
  justify-content: flex-start;
}
@media (max-width: 991px) {
  #who h1 {
    font-size: 26px;
    text-align: center;
    line-height: 35px;
    margin-bottom: 32px;
  }
  #who h1 .line {
    justify-content: center;
  }
}
#who p {
  color: #000;
  font-family: Playfair;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 20px;
}
@media (max-width: 991px) {
  #who p {
    font-size: 14px;
    text-align: center;
    line-height: 21px;
    margin-bottom: 12px;
  }
}
#who .subtitle {
  color: #0A0A0A;
  font-family: "termina", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 4.8px;
  text-transform: uppercase;
}
@media (max-width: 991px) {
  #who .subtitle {
    font-size: 10px;
    line-height: 21px;
    text-align: center;
  }
}
#who .title-section {
  color: #CC9C4A;
  font-family: Playfair;
  font-size: 45px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}
@media (max-width: 991px) {
  #who .title-section {
    font-size: 32px;
    line-height: 28px;
    text-align: center;
  }
}
#who .section1 {
  padding-bottom: 132px;
}
@media (max-width: 991px) {
  #who .section1 {
    padding-bottom: 60px;
  }
}
#who .section2 .video {
  position: relative;
  width: 97%;
  height: 823px;
  margin: 0 auto;
  display: flex;
}
@media (max-width: 991px) {
  #who .section2 .video {
    width: 100%;
    height: 574px;
  }
}
#who .section2 .video video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
#who .section2 .video .controls {
  display: flex;
}
#who .section2 .video .controls .play {
  box-sizing: border-box;
  width: 0;
  height: 15px;
  border-style: solid;
  border-width: 9px 0 9px 10px;
  border-color: transparent transparent transparent #ffffff;
  cursor: pointer;
  will-change: border-width;
  transition: all 0.2s ease;
  z-index: 999;
  position: absolute;
  left: 30.8%;
  bottom: 8.8%;
  opacity: 1;
}
@media (max-width: 991px) {
  #who .section2 .video .controls .play {
    right: 0;
    left: 5%;
  }
}
#who .section2 .video .controls .play.paused {
  border-style: double;
  border-width: 0px 0 0px 10px;
}
#who .section2 .video .controls .play:hover {
  border-color: transparent transparent transparent #ffffff;
}
#who .section2 .video .controls .speaker {
  height: 30px;
  width: 30px;
  right: 30.5%;
  bottom: 7.5%;
  position: absolute;
  overflow: hidden;
  display: inline-block;
  z-index: 999;
  opacity: 1;
}
@media (max-width: 991px) {
  #who .section2 .video .controls .speaker {
    left: 9.2% !important;
  }
}
@media (min-width: 1800px) {
  #who .section2 .video .controls .speaker {
    height: 33px;
  }
}
#who .section2 .video .controls .speaker span {
  display: block;
  width: 8px;
  height: 8px;
  background: #fff;
  margin: 11px 0 0 2px;
}
#who .section2 .video .controls .speaker span:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent #fff transparent transparent;
  border-width: 8px 15px 8px 14px;
  left: -12px;
  top: 7px;
}
#who .section2 .video .controls .speaker span:before {
  transform: rotate(45deg);
  border-radius: 0 50px 0 0;
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-style: double;
  border-color: #fff;
  border-width: 7px 7px 0 0;
  left: 20px;
  top: 9px;
  transition: all 0.2s ease-out;
}
#who .section2 .video .controls .speaker:hover span:before {
  transform: scale(0.8) translate(-3px, 0) rotate(42deg);
}
#who .section2 .video .controls .speaker.mute span:before {
  transform: scale(0.5) translate(-15px, 0) rotate(36deg);
  opacity: 0;
}
#who .section2 .video .controls .progress-bar {
  position: absolute;
  width: 34%;
  height: 2px;
  background-color: #fff;
  bottom: 9.5%;
  left: 0;
  right: 0;
  margin: 0 auto;
}
#who .section2 .video .controls .progress-bar .progress {
  height: 100%;
  width: 0;
  background-color: #CC9C4A;
}
#who .section3 {
  padding: 152px 0 147px 0;
}
@media (max-width: 991px) {
  #who .section3 {
    padding: 80px 0 56px 0;
  }
}
#who .section3 .subtitle {
  margin-left: 12px;
  margin-bottom: 11px;
}
@media (max-width: 991px) {
  #who .section3 .subtitle {
    margin-left: 0;
  }
}
#who .section3 .title-section {
  margin-left: 18px;
  margin-bottom: 44px;
}
#who .section3 .title-section .line {
  justify-content: flex-start;
}
@media (max-width: 991px) {
  #who .section3 .title-section {
    margin-left: 0;
  }
  #who .section3 .title-section .line {
    justify-content: center;
  }
}
#who .section3 p {
  margin-left: 77px;
  max-width: 553px;
}
@media (max-width: 991px) {
  #who .section3 p {
    margin-left: 0;
  }
}
#who .section3 .galeria {
  width: 682px;
  height: 548px;
  position: absolute;
  right: -89px;
}
@media (max-width: 1500px) {
  #who .section3 .galeria {
    width: 100%;
  }
}
@media (max-width: 991px) {
  #who .section3 .galeria {
    position: inherit;
    right: 0;
    height: 344px;
    margin-top: 56px;
  }
}
#who .section3 .galeria img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#who .section3 .galeria .slider-component {
  aspect-ratio: 1.2;
  position: relative;
  margin-bottom: 73px;
}
#who .section3 .galeria .slider-component img {
  width: 100%;
  object-fit: cover;
}
#who .section3 .galeria .slider-component .progress-bar {
  width: 80%;
  height: 2px;
  background-color: #CC9C4A;
  border-radius: 2px;
  overflow: hidden;
  position: absolute;
  bottom: 30px;
  left: 10%;
  z-index: 9;
  transform: matrix(-1, 0, 0, -1, 0, 0);
}
#who .section3 .galeria .slider-component .inside-bar {
  transform-origin: left center;
  border-radius: 2px;
  background-color: #fff;
  height: 2px;
  transform: scaleX(var(--progress));
}
#who .section3 .galeria .content--image {
  display: flex;
  width: 100%;
  height: 548px;
  overflow: hidden;
  position: relative;
}
@media (max-width: 991px) {
  #who .section3 .galeria .content--image {
    height: 344px;
  }
}
#who .section3 .galeria .content--image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}
#who .section4 {
  padding-bottom: 211px;
}
@media (max-width: 991px) {
  #who .section4 {
    padding-bottom: 0px;
  }
  #who .section4 .row {
    flex-direction: column-reverse;
  }
}
#who .section4 .subtitle {
  margin-top: 196px;
  margin-left: 29px;
  margin-bottom: 12px;
}
@media (max-width: 991px) {
  #who .section4 .subtitle {
    margin-top: 0;
    margin-left: 0;
  }
}
#who .section4 .title-section {
  margin-left: 31px;
  max-width: 439px;
  margin-bottom: 36px;
}
#who .section4 .title-section .line {
  justify-content: flex-start;
}
@media (max-width: 991px) {
  #who .section4 .title-section {
    margin-left: 0;
  }
  #who .section4 .title-section .line {
    justify-content: center;
  }
}
#who .section4 p {
  margin-left: 96px;
}
@media (max-width: 991px) {
  #who .section4 p {
    margin-left: 0;
  }
}
#who .section4 .galeria {
  width: 694px;
  height: 733px;
  position: absolute;
  left: -99px;
}
@media (max-width: 1500px) {
  #who .section4 .galeria {
    width: 100%;
  }
}
@media (max-width: 991px) {
  #who .section4 .galeria {
    position: inherit;
    left: 0;
    height: 618px;
    margin-top: 56px;
  }
}
#who .section4 .galeria img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#who .section4 .galeria .slider-component {
  aspect-ratio: 1.2;
  position: relative;
  margin-bottom: 73px;
}
#who .section4 .galeria .slider-component img {
  width: 100%;
  object-fit: cover;
}
#who .section4 .galeria .slider-component .progress-bar {
  width: 80%;
  height: 2px;
  background-color: #CC9C4A;
  border-radius: 2px;
  overflow: hidden;
  position: absolute;
  bottom: 30px;
  left: 10%;
  z-index: 9;
  transform: matrix(-1, 0, 0, -1, 0, 0);
}
#who .section4 .galeria .slider-component .inside-bar {
  transform-origin: left center;
  border-radius: 2px;
  background-color: #fff;
  height: 2px;
  transform: scaleX(var(--progress));
}
#who .section4 .galeria .content--image {
  display: flex;
  width: 100%;
  height: 733px;
  overflow: hidden;
  position: relative;
}
@media (max-width: 991px) {
  #who .section4 .galeria .content--image {
    height: 618px;
  }
}
#who .section4 .galeria .content--image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}
#who .section4 .galeria .content--image .image-wrap {
  width: 100% !important;
  height: 100%;
  object-fit: cover;
}
#who .section5 {
  padding: 119px 0 238px 0;
  background: url("../img/who/bg-section5.webp");
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #001F27;
}
@media (max-width: 991px) {
  #who .section5 {
    padding: 60px 0 104px 0;
  }
}
#who .section5 .subtitle {
  color: #CC9C4A;
  margin-left: 15px;
  margin-bottom: 12px;
}
@media (max-width: 991px) {
  #who .section5 .subtitle {
    margin-left: 0;
  }
}
#who .section5 .title-section,
#who .section5 p {
  color: #ffffff;
}
#who .section5 .title-section {
  margin-left: 15px;
  max-width: 733px;
  margin-bottom: 48px;
}
#who .section5 .title-section .line {
  justify-content: flex-start;
}
@media (max-width: 991px) {
  #who .section5 .title-section {
    margin-left: 0;
  }
  #who .section5 .title-section .line {
    justify-content: center;
  }
}
#who .section5 p {
  margin-left: 79px;
  max-width: 555px;
}
@media (max-width: 991px) {
  #who .section5 p {
    margin-left: 0;
  }
}
#who .section5 .galeria {
  width: 520px;
  height: 576px;
  position: absolute;
  left: 11px;
  top: 42px;
}
@media (max-width: 1500px) {
  #who .section5 .galeria {
    width: 100%;
  }
}
@media (max-width: 991px) {
  #who .section5 .galeria {
    position: inherit;
    left: 0;
    height: 474px;
    margin-top: 0px;
  }
}
#who .section5 .galeria img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#who .section5 .galeria .slider-component {
  aspect-ratio: 1.2;
  position: relative;
  margin-bottom: 73px;
}
#who .section5 .galeria .slider-component img {
  width: 100%;
  object-fit: cover;
}
#who .section5 .galeria .slider-component .progress-bar {
  width: 80%;
  height: 2px;
  background-color: #CC9C4A;
  border-radius: 2px;
  overflow: hidden;
  position: absolute;
  bottom: 30px;
  left: 10%;
  z-index: 9;
  transform: matrix(-1, 0, 0, -1, 0, 0);
}
#who .section5 .galeria .slider-component .inside-bar {
  transform-origin: left center;
  border-radius: 2px;
  background-color: #fff;
  height: 2px;
  transform: scaleX(var(--progress));
}
#who .section5 .galeria .content--image {
  display: flex;
  width: 100%;
  height: 576px;
  overflow: hidden;
  position: relative;
}
@media (max-width: 991px) {
  #who .section5 .galeria .content--image {
    height: 474px;
  }
}
#who .section5 .galeria .content--image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}
#who .section5 .galeria .content--image .image-wrap {
  width: 100% !important;
  height: 100%;
  object-fit: cover;
}
#who .section6 {
  padding: 160px 0 163px 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
@media (max-width: 991px) {
  #who .section6 {
    padding: 100px 0;
  }
}
#who .section6 .container {
  z-index: 2;
  position: relative;
}
#who .section6 .subtitle,
#who .section6 .title-section {
  color: #fff;
  text-align: center;
}
#who .section6 .subtitle {
  margin-bottom: 12px;
}
#who .section6 .title-section {
  font-size: 80px;
  line-height: 65px;
  margin-bottom: 86px;
}
#who .section6 .title-section .line {
  justify-content: center;
}
@media (max-width: 991px) {
  #who .section6 .title-section {
    font-size: 56px;
    line-height: 50px;
    margin-bottom: 56px;
  }
}
#who .section6 .btn-section {
  display: inline-block;
  text-decoration: none;
  position: relative;
  font-family: "termina", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 3.9px;
  color: #ffffff;
  text-transform: uppercase;
}
#who .section6 .btn-section::before {
  position: absolute;
  bottom: -13px;
  left: -27px;
  content: "";
  width: 121px;
  height: 2.5px;
  background-color: #CC9C4A;
}
#who .section6 .btn2 span {
  color: #ffffff;
}
#who .section6 .btn2-4 span:hover {
  color: #CC9C4A;
}
#who .section6 .layer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

#contact {
  padding: 247px 0 0 0;
}
#contact h1 {
  color: #CC9C4A;
  font-family: "termina", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 44px;
  font-style: normal;
  font-weight: 400;
  line-height: 66px;
  letter-spacing: 17.6px;
  margin-bottom: 51px;
}
#contact h1 .line {
  justify-content: start;
}
@media (max-width: 991px) {
  #contact h1 {
    font-size: 26px;
    text-align: center;
    line-height: 35px;
    margin-bottom: 32px;
  }
  #contact h1 .line {
    justify-content: center;
  }
}
#contact p {
  color: #000;
  font-family: Playfair;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 20px;
}
@media (max-width: 991px) {
  #contact p {
    font-size: 14px;
    text-align: center;
    line-height: 21px;
    margin-bottom: 12px;
  }
}
#contact .subtitle {
  color: #0A0A0A;
  font-family: "termina", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 4.8px;
  text-transform: uppercase;
}
@media (max-width: 991px) {
  #contact .subtitle {
    font-size: 10px;
    line-height: 21px;
    text-align: center;
  }
}
#contact .title-section {
  color: #CC9C4A;
  font-family: Playfair;
  font-size: 45px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}
#contact .title-section .line {
  justify-content: flex-start;
}
@media (max-width: 991px) {
  #contact .title-section {
    font-size: 32px;
    line-height: 28px;
    text-align: center;
  }
  #contact .title-section .line {
    justify-content: center;
  }
}
#contact .section1 {
  padding-bottom: 308px;
}
@media (max-width: 991px) {
  #contact .section1 {
    padding-bottom: 19px;
  }
}
#contact .section1 p {
  margin-left: 12px;
  max-width: 500px;
}
@media (max-width: 991px) {
  #contact .section1 p {
    margin-left: 0;
  }
}
#contact .section1 .redes {
  margin-top: 40px;
  margin-left: 12px;
  display: flex;
  gap: 18px;
}
@media (max-width: 991px) {
  #contact .section1 .redes {
    justify-content: center;
    margin-top: 32px;
  }
}
#contact .section1 .redes a {
  display: inline-block;
  text-decoration: none;
}
#contact .section1 .video-contato {
  width: 870px;
  height: 521px;
  position: absolute;
  right: 0;
}
@media (max-width: 1500px) {
  #contact .section1 .video-contato {
    width: 50%;
  }
}
@media (max-width: 991px) {
  #contact .section1 .video-contato {
    width: 100%;
    position: relative;
    margin-top: 70px;
  }
}
#contact .section1 .video {
  position: relative;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  display: flex;
}
#contact .section1 .video video {
  object-fit: cover;
  width: 100%;
  height: 100%;
}
#contact .section1 .video .controls {
  display: flex;
}
#contact .section1 .video .controls .play {
  box-sizing: border-box;
  width: 0;
  height: 15px;
  border-style: solid;
  border-width: 9px 0 9px 10px;
  border-color: transparent transparent transparent #ffffff;
  cursor: pointer;
  will-change: border-width;
  transition: all 0.2s ease;
  z-index: 999;
  position: absolute;
  left: 30.8%;
  bottom: 7.8%;
  opacity: 1;
}
@media (max-width: 991px) {
  #contact .section1 .video .controls .play {
    right: 0;
    left: 5%;
  }
}
#contact .section1 .video .controls .play.paused {
  border-style: double;
  border-width: 0px 0 0px 10px;
}
#contact .section1 .video .controls .play:hover {
  border-color: transparent transparent transparent #ffffff;
}
#contact .section1 .video .controls .speaker {
  height: 30px;
  width: 30px;
  right: 28.5%;
  bottom: 6.5%;
  position: absolute;
  overflow: hidden;
  display: inline-block;
  z-index: 999;
  opacity: 1;
}
@media (max-width: 991px) {
  #contact .section1 .video .controls .speaker {
    left: 9.2% !important;
  }
}
@media (min-width: 1800px) {
  #contact .section1 .video .controls .speaker {
    height: 33px;
  }
}
#contact .section1 .video .controls .speaker span {
  display: block;
  width: 8px;
  height: 8px;
  background: #fff;
  margin: 11px 0 0 2px;
}
#contact .section1 .video .controls .speaker span:after {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-color: transparent #fff transparent transparent;
  border-width: 8px 15px 8px 14px;
  left: -12px;
  top: 7px;
}
#contact .section1 .video .controls .speaker span:before {
  transform: rotate(45deg);
  border-radius: 0 50px 0 0;
  content: "";
  position: absolute;
  width: 10px;
  height: 10px;
  border-style: double;
  border-color: #fff;
  border-width: 7px 7px 0 0;
  left: 20px;
  top: 9px;
  transition: all 0.2s ease-out;
}
#contact .section1 .video .controls .speaker:hover span:before {
  transform: scale(0.8) translate(-3px, 0) rotate(42deg);
}
#contact .section1 .video .controls .speaker.mute span:before {
  transform: scale(0.5) translate(-15px, 0) rotate(36deg);
  opacity: 0;
}
#contact .section1 .video .controls .progress-bar {
  position: absolute;
  width: 34%;
  height: 2px;
  background-color: #fff;
  bottom: 9.5%;
  left: 0;
  right: 0;
  margin: 0 auto;
}
#contact .section1 .video .controls .progress-bar .progress {
  height: 100%;
  width: 0;
  background-color: #CC9C4A;
}
#contact .section2, #contact a {
  color: #0A0A0A;
  font-family: Playfair;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  padding-bottom: 61px;
  text-decoration: none;
}
#contact .section2 .subtitle, #contact a .subtitle {
  margin-bottom: 12px;
  margin-left: 12px;
}
@media (max-width: 991px) {
  #contact .section2 .subtitle, #contact a .subtitle {
    margin-left: 0;
    margin-top: 30px;
  }
}
#contact .section2 .title-section, #contact a .title-section {
  margin-left: 12px;
  margin-bottom: 42px;
}
@media (max-width: 991px) {
  #contact .section2 .title-section, #contact a .title-section {
    margin-left: 0;
  }
}
#contact .section2 .e-mail,
#contact .section2 .telefone,
#contact .section2 .endereco, #contact a .e-mail,
#contact a .telefone,
#contact a .endereco {
  display: inherit;
  align-items: center;
  margin-left: 29px;
  margin-bottom: 21px;
}
@media (max-width: 991px) {
  #contact .section2 .e-mail,
#contact .section2 .telefone,
#contact .section2 .endereco, #contact a .e-mail,
#contact a .telefone,
#contact a .endereco {
    text-align: center;
    margin-left: 0px;
  }
}
#contact .section2 .e-mail svg,
#contact .section2 .telefone svg,
#contact .section2 .endereco svg, #contact a .e-mail svg,
#contact a .telefone svg,
#contact a .endereco svg {
  margin-right: 20px;
}
@media (max-width: 991px) {
  #contact .section2 .e-mail svg,
#contact .section2 .telefone svg,
#contact .section2 .endereco svg, #contact a .e-mail svg,
#contact a .telefone svg,
#contact a .endereco svg {
    width: 90%;
  }
}
#contact .section3 {
  position: relative;
  margin-top: 226px;
  padding: 481px 0 165px 0;
  background-image: url("../img/contact/textura002.webp");
  background-size: contain;
  background-repeat: no-repeat;
  background-color: #001F27;
  background-position: bottom;
}
@media (max-width: 991px) {
  #contact .section3 {
    padding: 190px 0 165px 0;
  }
}
#contact .section3 .mapa {
  width: 80%;
  height: 593px;
  position: absolute;
  top: -114px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
@media (max-width: 991px) {
  #contact .section3 .mapa {
    width: 100%;
  }
}
#contact .section3 #mapa {
  width: 100%;
  height: 593px;
}
@media (max-width: 1100px) {
  #contact .section3 #mapa {
    height: 331px;
  }
}
#contact .section3 .subtitle {
  color: #ffffff;
  margin-left: 12px;
  margin-bottom: 11px;
}
@media (max-width: 991px) {
  #contact .section3 .subtitle {
    margin-left: 0;
  }
}
#contact .section3 .title-section {
  margin-left: 13px;
  margin-bottom: 38px;
}
@media (max-width: 991px) {
  #contact .section3 .title-section {
    margin-left: 0;
  }
}
#contact .section3 p {
  color: #ffffff;
  margin-left: 77px;
  max-width: 500px;
}
@media (max-width: 991px) {
  #contact .section3 p {
    margin-left: 0;
  }
}
#contact .section3 form {
  margin-top: 75px;
  margin-left: 78px;
}
@media (max-width: 991px) {
  #contact .section3 form {
    margin-left: 0px;
  }
}
#contact .section3 form label,
#contact .section3 form input {
  width: 100%;
  max-width: 500px;
}
#contact .section3 form textarea {
  width: 100%;
  max-width: 595px;
}
#contact .section3 form label {
  color: #CC9C4A;
  font-family: "termina", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  margin-bottom: 12px;
}
#contact .section3 form textarea,
#contact .section3 form input {
  padding: 15px 32px;
  color: rgba(255, 255, 255, 0.6);
  font-family: "termina", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  margin-bottom: 20px;
  border: 1px solid #FFF;
  background-color: transparent;
}
#contact .section3 form textarea::placeholder,
#contact .section3 form input::placeholder {
  color: rgba(255, 255, 255, 0.6);
  font-family: "termina", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}
#contact .section3 form textarea {
  min-height: 160px;
}
#contact .section3 form input[type=checkbox] {
  width: 20px;
  background-color: transparent;
  border: 1px solid var(--accent-color, #CC9C4A);
  padding: 0;
  margin: 0;
}
#contact .section3 form .termos {
  display: flex;
  margin-top: 48px;
  gap: 20px;
}
@media (max-width: 991px) {
  #contact .section3 form .termos {
    margin-top: 20px;
    margin-bottom: 56px;
  }
}
#contact .section3 form .termos p {
  padding: 0;
  margin: 0;
  color: #FFF;
  font-family: "termina", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}
#contact .section3 form .termos p a {
  color: #FFF;
  font-family: "termina", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}
#contact .section3 form input[type=submit] {
  max-width: 150px;
  color: #FFF;
  text-align: center;
  font-family: "termina", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 3.9px;
  text-transform: uppercase;
  border: none;
  position: relative;
  margin-top: 35px;
}
#contact .section3 form .btn2 span {
  color: #ffffff;
}
#contact .section3 form .btn2-4 span:hover {
  color: #CC9C4A;
}

#wine {
  background: #003644;
  /*animated scroll arrow animation*/
}
@media (max-width: 1100px) {
  #wine {
    padding-top: 200px;
  }
}
#wine .container {
  max-width: 1234px;
}
#wine .content-load {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr 1fr;
  height: 100dvh;
  align-items: center;
}
@media (max-width: 1100px) {
  #wine .content-load {
    display: flex;
    flex-direction: column;
    height: auto;
  }
  #wine .content-load .wine-content {
    margin-top: 5rem;
  }
}
#wine .content-load2 {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr 1fr;
  min-height: 90dvh;
  padding-bottom: 200px;
  margin-top: 200px;
}
@media (max-width: 1100px) {
  #wine .content-load2 {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 991px) {
  #wine .content-load2 {
    display: block !important;
    flex-direction: column;
    padding-bottom: 0px;
    margin-top: 0px;
  }
  #wine .content-load2 .box {
    display: none;
  }
}
#wine .content-load2 .btn-box {
  position: relative;
  top: 40%;
  transform: scale(0.8);
  left: -10%;
}
#wine .content-load2 .sub {
  margin-bottom: 40px;
  margin-top: 40px;
}
#wine .wrapper-wine {
  position: relative;
}
#wine .wine-content {
  transform: translate(13px, -45px);
  padding-left: 1rem;
  padding-top: 5rem;
}
@media (max-width: 1299px) {
  #wine .wine-content {
    transform: scale(0.8);
  }
}
@media (max-height: 799px) {
  #wine .wine-content {
    transform: scale(1);
  }
}
#wine .wine-img span {
  color: #fff;
}
#wine .wine-img span:hover {
  color: #CC9C4A;
}
#wine .wine-img .btn2 {
  position: fixed;
  left: 32.5%;
  bottom: 10%;
  opacity: 0;
  z-index: 9999;
}
@media (max-width: 1599px) {
  #wine .wine-img .btn2 {
    left: 30.5%;
  }
}
@media (max-width: 1299px) {
  #wine .wine-img .btn2 {
    left: 26%;
  }
}
@media (max-width: 1100px) {
  #wine .wine-img .btn2 {
    opacity: 1;
    position: relative;
    bottom: 0;
    left: 17%;
    bottom: -33px;
  }
}
#wine .wine-img .wine-name {
  color: #FFF;
  text-align: center;
  font-family: "Playfair";
  font-size: 100px;
  font-style: normal;
  font-weight: 500;
  line-height: 80px;
  position: fixed;
  left: 29.5%;
  bottom: 34%;
  max-width: 22%;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  transform: scale(0.8);
}
@media (max-width: 1599px) {
  #wine .wine-img .wine-name {
    left: 25.5%;
  }
}
@media (max-width: 1299px) {
  #wine .wine-img .wine-name {
    transform: scale(0.8);
    left: 21%;
    max-width: 30%;
  }
}
@media (max-width: 1100px) {
  #wine .wine-img .wine-name {
    position: relative;
    left: 0;
    top: 0;
    font-size: 70px;
    line-height: 70px;
    max-width: 100%;
    opacity: 1;
  }
}
#wine .wine-img img {
  position: fixed;
  left: 32%;
  bottom: 20%;
  max-height: 549px;
  z-index: 1;
  pointer-events: none;
  transform: scale(1.2);
}
@media (max-width: 1599px) {
  #wine .wine-img img {
    left: 29%;
    top: 20%;
  }
}
@media (max-width: 1299px) {
  #wine .wine-img img {
    transform: scale(0.8);
    left: 25%;
  }
}
@media (max-width: 1100px) {
  #wine .wine-img img {
    position: relative;
    left: 0;
    top: 0;
  }
}
@media (max-width: 991px) {
  #wine .wine-img img {
    position: relative;
    left: 16%;
    top: 0;
    max-height: 358px;
    transform: scale(1);
  }
}
#wine .title {
  font-size: 122px;
  line-height: 100px;
  font-family: "Playfair";
  color: #fff;
  margin-bottom: 37px;
}
#wine .title .line {
  justify-content: start;
}
@media (max-width: 991px) {
  #wine .title {
    font-size: 62px;
    line-height: 62px;
  }
}
#wine .sub {
  color: #FFF;
  font-family: "Playfair";
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 53.333% */
  margin-bottom: 2rem;
}
#wine .subtitle {
  color: #FFF;
  font-family: "termina", sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 300;
  line-height: 16px;
  margin-bottom: 41px;
}
#wine .text {
  color: #FFF;
  font-family: "termina", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  /* 208.333% */
}
#wine .desc {
  color: #FFF;
  font-family: "termina", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}
#wine .desc span {
  color: #FFF;
  font-family: "termina", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 25px;
  /* 208.333% */
}
#wine .btn-box {
  display: flex;
  gap: 23px;
  text-decoration: none;
  align-items: center;
  margin-top: 50px;
}
#wine .btn-box span {
  color: #FFF;
  font-family: " Playfair";
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 16.5px;
  /* 91.667% */
}
#wine .btn-down2 {
  cursor: pointer;
  width: 76px;
  height: 76px;
  bottom: 77px;
  left: 16.6vw;
  background: url(../img/seta.svg) center center no-repeat;
  background-size: contain;
  overflow: hidden;
}
#wine .ca3-scroll-down-link {
  cursor: pointer;
  height: 30px;
  width: 15px;
  position: absolute;
  left: 0;
  right: 0;
  top: 20px;
  margin: 0 auto;
  z-index: 100;
  -webkit-animation: ca3_fade_move_down 1s ease-in-out infinite;
  -moz-animation: ca3_fade_move_down 1s ease-in-out infinite;
  animation: ca3_fade_move_down 1s ease-in-out infinite;
}
#wine .ca3-scroll-down-arrow {
  background-image: url("../img/seta-home.png");
  background-size: contain;
  background-repeat: no-repeat;
}
@-webkit-keyframes ca3_fade_move_down {
  0% {
    -webkit-transform: translate(0, -20px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 20px);
    opacity: 0;
  }
}
@-moz-keyframes ca3_fade_move_down {
  0% {
    -moz-transform: translate(0, -20px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -moz-transform: translate(0, 20px);
    opacity: 0;
  }
}
@keyframes ca3_fade_move_down {
  0% {
    transform: translate(0, -20px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 20px);
    opacity: 0;
  }
}
#wine .wines-box {
  display: flex;
  flex-direction: column;
  gap: 70px;
  position: fixed;
  top: 47vh;
}
@media (max-width: 1100px) {
  #wine .wines-box {
    flex-direction: row;
    overflow-x: scroll;
    width: 100vw;
    overflow-y: hidden;
    height: 100px;
    padding-inline: 2rem;
    position: inherit;
    top: inherit;
  }
}
#wine .card-wine {
  display: flex;
  gap: 28px;
  text-decoration: none;
  align-items: center;
  position: relative;
}
#wine .card-wine::after {
  content: "";
  position: absolute;
  bottom: -21px;
  width: 198%;
  height: 2px;
  background-color: #fff;
  left: -140%;
  transition: all 0.5s ease-in-out;
  transform-origin: left center;
  transform: scaleX(0);
}
#wine .card-wine.active::after {
  transform: scaleX(1);
}
#wine .card-wine img {
  max-height: 57px;
  transition: all 0.5s ease-in-out;
}
#wine .card-wine p {
  color: #CC9C4A;
  font-family: " Playfair";
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 96% */
  max-width: 50%;
  margin-bottom: 0;
  transition: all 0.5s ease-in-out;
}
#wine .card-wine:hover p {
  color: #fff;
}
#wine .card-wine:hover::after {
  transform: scaleX(1);
}

.wine-page .banner-paralax {
  height: 545px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
  position: relative;
}
.wine-page .banner-paralax .btn2::after {
  content: "";
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.3;
}
.wine-page .banner-paralax .btn2 {
  width: fit-content;
}
.wine-page .banner-paralax .paralax-content {
  max-width: 730px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  position: relative;
  z-index: 9;
}
.wine-page .banner-paralax .paralax-content .btn2 span {
  color: #ffffff;
}
.wine-page .banner-paralax .paralax-content .btn2-4 span:hover {
  color: #CC9C4A;
}
.wine-page .banner-paralax .title {
  color: #FFF;
  text-align: center;
  /* desktop/H1 - Title */
  font-family: Playfair;
  font-size: 80px;
  font-style: normal;
  font-weight: 300;
  line-height: 65px;
  /* 81.25% */
}
.wine-page .banner-paralax .title strong {
  color: #CC9C4A;
}
.wine-page .banner-paralax .text {
  color: #FFF;
  text-align: center;
  font-family: Playfair;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  /* 100% */
}
.wine-page .banner-paralax .btn-find {
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 230.769% */
  letter-spacing: 3.9px;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 1.5rem;
  position: relative;
  font-family: "Termina Test Demi";
  width: fit-content;
  margin: 0 auto;
}
.wine-page .banner-paralax .btn-find:hover::after {
  width: 100%;
}
.wine-page .banner-paralax .btn-find::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40%;
  height: 1px;
  background: #CC9C4A;
  transition: all 0.2s ease-in;
}

#purpose {
  --progress: 0;
  padding: 248px 0 0 0;
}
@media (max-width: 991px) {
  #purpose {
    padding: 180px 0 0 0;
  }
}
#purpose h1 {
  font-family: "termina", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 38px;
  line-height: 57px;
  letter-spacing: 15.2px;
  margin-left: 12px;
  color: #CC9C4A;
}
@media (max-width: 991px) {
  #purpose h1 {
    font-size: 26px;
    text-align: center;
    line-height: 35px;
    margin-bottom: 32px;
  }
}
#purpose p {
  color: #000;
  font-family: Playfair;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  margin-bottom: 20px;
}
@media (max-width: 991px) {
  #purpose p {
    font-size: 14px;
    text-align: center;
    line-height: 21px;
    margin-bottom: 12px;
  }
}
#purpose .subtitle {
  color: #0A0A0A;
  font-family: "termina", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  letter-spacing: 4.8px;
  text-transform: uppercase;
}
@media (max-width: 991px) {
  #purpose .subtitle {
    font-size: 10px;
    line-height: 21px;
    text-align: center;
  }
}
#purpose .title-section {
  color: #CC9C4A;
  font-family: Playfair;
  font-size: 45px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
}
#purpose .title-section .line {
  justify-content: start;
}
@media (max-width: 991px) {
  #purpose .title-section {
    font-size: 32px;
    line-height: 28px;
    text-align: center;
  }
  #purpose .title-section .line {
    justify-content: center;
  }
}
#purpose .section1 {
  padding-bottom: 188px;
}
@media (max-width: 991px) {
  #purpose .section1 {
    padding-bottom: 80px;
  }
  #purpose .section1 .row {
    flex-direction: column-reverse;
    padding-bottom: 0;
  }
}
#purpose .section1 .subtitle {
  margin-top: 9px;
  margin-left: 16px;
  margin-bottom: 12px;
}
@media (max-width: 991px) {
  #purpose .section1 .subtitle {
    margin-left: 0;
  }
}
#purpose .section1 .title-section {
  margin-left: 19px;
  max-width: 439px;
  margin-bottom: 43px;
}
@media (max-width: 991px) {
  #purpose .section1 .title-section {
    margin-left: 0;
  }
}
#purpose .section1 p {
  margin-left: 83px;
}
@media (max-width: 991px) {
  #purpose .section1 p {
    margin-left: 0;
  }
}
#purpose .section1 .galeria {
  width: 665px;
  height: 444px;
  position: absolute;
  left: -45px;
  top: 8px;
}
@media (max-width: 1500px) {
  #purpose .section1 .galeria {
    width: 100%;
  }
}
@media (max-width: 991px) {
  #purpose .section1 .galeria {
    position: inherit;
    left: 0;
    top: 0;
    height: 286px;
    margin-top: 56px;
    margin-bottom: 0px;
  }
}
#purpose .section1 .galeria img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#purpose .section1 .galeria .slider-component {
  aspect-ratio: 1.2;
  position: relative;
  margin-bottom: 73px;
}
#purpose .section1 .galeria .slider-component img {
  width: 100%;
  object-fit: cover;
}
#purpose .section1 .galeria .slider-component .progress-bar {
  width: 80%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 2px;
  overflow: hidden;
  position: absolute;
  bottom: 30px;
  left: 10%;
  z-index: 9;
  transform: matrix(-1, 0, 0, -1, 0, 0);
}
#purpose .section1 .galeria .slider-component .inside-bar {
  transform-origin: left center;
  border-radius: 2px;
  background-color: rgb(255, 255, 255);
  height: 2px;
  transform: scaleX(var(--progress));
}
#purpose .section1 .galeria .content--image {
  display: flex;
  width: 100%;
  height: 444px;
  overflow: hidden;
  position: relative;
}
@media (max-width: 991px) {
  #purpose .section1 .galeria .content--image {
    height: 286px;
  }
}
#purpose .section1 .galeria .content--image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}
#purpose .section1 .galeria .content--image .image-wrap {
  width: 100% !important;
  height: 100%;
  object-fit: cover;
}
#purpose .bg-section {
  background: url("../img/purpose/bg-proposito2.webp") bottom center no-repeat;
  background-size: contain;
}
#purpose .section2 {
  position: relative;
  padding-bottom: 0px;
}
#purpose .section2 .btn-find {
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 230.769% */
  letter-spacing: 3.9px;
  color: #0A0A0A;
  text-transform: uppercase;
  text-decoration: none;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 1.5rem;
  position: relative;
  font-family: "Termina Test Demi";
  width: fit-content;
  margin: 0 auto;
}
#purpose .section2 .btn-find:hover::after {
  width: 100%;
}
#purpose .section2 .btn-find::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40%;
  height: 1px;
  background: #CC9C4A;
  transition: all 0.2s ease-in;
}
#purpose .section2 .text-destaque {
  color: #000;
  text-align: center;
  font-family: "Playfair";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  max-width: 58%;
  margin: 0 auto;
  margin-bottom: 50px;
  margin-top: 118px;
}
#purpose .section2 .img-gif {
  width: 100%;
  max-height: 630px;
  object-fit: cover;
}
@media (max-width: 991px) {
  #purpose .section2 .img-gif {
    max-height: 430px;
  }
}
#purpose .section2 .text-marquee {
  position: absolute;
  top: 37%;
}
@media (max-width: 991px) {
  #purpose .section2 .text-marquee {
    max-width: 100%;
  }
}
#purpose .section2 .container {
  max-width: 1425px;
  margin: 0 auto;
}
#purpose .section2 .content--image {
  display: flex;
  width: 100%;
  height: 630px;
  overflow: hidden;
  position: relative;
}
@media (max-width: 991px) {
  #purpose .section2 .content--image {
    height: 430px;
  }
}
#purpose .section2 .content--image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}
#purpose .section2 .enter-image-style {
  background-repeat: no-repeat;
  background-size: cover;
}
#purpose .section3 {
  padding: 156px 0 147px 0;
}
@media (max-width: 991px) {
  #purpose .section3 {
    padding: 86px 0 43px 0;
  }
}
#purpose .section3 .subtitle {
  margin-left: 41px;
  margin-bottom: 11px;
}
@media (max-width: 991px) {
  #purpose .section3 .subtitle {
    margin-left: 0;
  }
}
#purpose .section3 .title-section {
  margin-left: 41px;
  margin-bottom: 44px;
}
@media (max-width: 991px) {
  #purpose .section3 .title-section {
    margin-left: 0;
  }
}
#purpose .section3 p {
  margin-left: 106px;
  max-width: 553px;
}
@media (max-width: 991px) {
  #purpose .section3 p {
    margin-left: 0;
  }
}
#purpose .section3 .galeria {
  width: 682px;
  height: 548px;
  position: absolute;
  right: -89px;
}
@media (max-width: 1500px) {
  #purpose .section3 .galeria {
    width: 100%;
  }
}
@media (max-width: 991px) {
  #purpose .section3 .galeria {
    position: inherit;
    right: 0;
    height: 361px;
    margin-top: 56px;
  }
}
#purpose .section3 .galeria img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#purpose .section3 .galeria .slider-component {
  aspect-ratio: 1.2;
  position: relative;
  margin-bottom: 73px;
}
#purpose .section3 .galeria .slider-component img {
  width: 100%;
  object-fit: cover;
}
#purpose .section3 .galeria .slider-component .progress-bar {
  width: 80%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 2px;
  overflow: hidden;
  position: absolute;
  bottom: 30px;
  left: 10%;
  z-index: 9;
  transform: matrix(-1, 0, 0, -1, 0, 0);
}
#purpose .section3 .galeria .slider-component .inside-bar {
  transform-origin: left center;
  border-radius: 2px;
  background-color: rgb(255, 255, 255);
  height: 2px;
  transform: scaleX(var(--progress));
}
#purpose .section3 .galeria .content--image {
  display: flex;
  width: 100%;
  height: 548px;
  overflow: hidden;
  position: relative;
}
@media (max-width: 991px) {
  #purpose .section3 .galeria .content--image {
    height: 361px;
  }
}
#purpose .section3 .galeria .content--image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}
#purpose .section4 {
  padding-bottom: 441px;
}
@media (max-width: 991px) {
  #purpose .section4 {
    padding-bottom: 130px;
  }
  #purpose .section4 .row {
    flex-direction: column-reverse;
  }
}
#purpose .section4 .subtitle {
  margin-top: 289px;
  margin-left: 58px;
  margin-bottom: 12px;
}
@media (max-width: 991px) {
  #purpose .section4 .subtitle {
    margin-left: 0;
    margin-top: 0;
  }
}
#purpose .section4 .title-section {
  margin-left: 58px;
  max-width: 439px;
  margin-bottom: 42px;
}
@media (max-width: 991px) {
  #purpose .section4 .title-section {
    margin-left: 0;
  }
}
#purpose .section4 p {
  margin-left: 123px;
}
@media (max-width: 991px) {
  #purpose .section4 p {
    margin-left: 0;
  }
}
#purpose .section4 .galeria {
  width: 694px;
  height: 733px;
  position: absolute;
  left: -62px;
  top: 91px;
}
@media (max-width: 1500px) {
  #purpose .section4 .galeria {
    width: 100%;
  }
}
@media (max-width: 991px) {
  #purpose .section4 .galeria {
    position: inherit;
    left: 0;
    top: 0;
    height: 444px;
    margin-top: 56px;
  }
}
#purpose .section4 .galeria img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#purpose .section4 .galeria .slider-component {
  aspect-ratio: 1.2;
  position: relative;
  margin-bottom: 73px;
}
#purpose .section4 .galeria .slider-component img {
  width: 100%;
  object-fit: cover;
}
#purpose .section4 .galeria .slider-component .progress-bar {
  width: 80%;
  height: 2px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 2px;
  overflow: hidden;
  position: absolute;
  bottom: 30px;
  left: 10%;
  z-index: 9;
  transform: matrix(-1, 0, 0, -1, 0, 0);
}
#purpose .section4 .galeria .slider-component .inside-bar {
  transform-origin: left center;
  border-radius: 2px;
  background-color: rgb(255, 255, 255);
  height: 2px;
  transform: scaleX(var(--progress));
}
#purpose .section4 .galeria .content--image {
  display: flex;
  width: 100%;
  height: 733px;
  overflow: hidden;
  position: relative;
}
@media (max-width: 991px) {
  #purpose .section4 .galeria .content--image {
    position: inherit;
    right: 0;
    height: 444px;
    margin-top: 56px;
  }
}
#purpose .section4 .galeria .content--image img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}
#purpose .section4 .galeria .content--image .image-wrap {
  width: 100% !important;
  height: 100%;
  object-fit: cover;
}
#purpose .section6 {
  padding: 160px 0 163px 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
}
#purpose .section6 .container {
  z-index: 2;
  position: relative;
}
@media (max-width: 991px) {
  #purpose .section6 {
    padding: 100px 0 100px 0;
  }
}
#purpose .section6 .subtitle,
#purpose .section6 .title-section {
  color: #fff;
  text-align: center;
}
#purpose .section6 .subtitle {
  margin-bottom: 12px;
}
#purpose .section6 .title-section {
  font-size: 80px;
  line-height: 65px;
  margin-bottom: 86px;
}
#purpose .section6 .title-section .line {
  justify-content: center;
}
@media (max-width: 991px) {
  #purpose .section6 .title-section {
    font-size: 56px;
    line-height: 50px;
  }
}
#purpose .section6 .btn-section {
  display: inline-block;
  text-decoration: none;
  position: relative;
  font-family: "termina", sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  letter-spacing: 3.9px;
  color: #ffffff;
  text-transform: uppercase;
}
#purpose .section6 .btn-section::before {
  position: absolute;
  bottom: -13px;
  left: -27px;
  content: "";
  width: 121px;
  height: 2.5px;
  background-color: #CC9C4A;
}
#purpose .section6 .btn2 span {
  color: #ffffff;
}
#purpose .section6 .btn2-4 span:hover {
  color: #CC9C4A;
}
#purpose .section6 .layer {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1;
}

#places .mySwiper3 .swiper-slide-active img {
  animation: fadein 1.8s;
  animation-timing-function: cubic-bezier(0, 0.28, 0.435, 1.06);
}
@keyframes fadein {
  0% {
    transform: scale(1.07);
  }
  100% {
    transform: scale(1);
  }
}
#places .banner-margin {
  min-height: 95dvh;
  z-index: 9;
  position: relative;
  /*animated scroll arrow animation*/
}
@media (max-width: 991px) {
  #places .banner-margin {
    margin: 0px;
  }
}
#places .banner-margin .slider-full {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 95dvh;
}
#places .banner-margin .slider-full .swiper-button-prev,
#places .banner-margin .slider-full .swiper-rtl .swiper-button-next,
#places .banner-margin .slider-full .swiper-button-next,
#places .banner-margin .slider-full .swiper-rtl .swiper-button-prev {
  width: 50px;
  height: 50px;
}
#places .banner-margin .slider-full .swiper-button-prev::after,
#places .banner-margin .slider-full .swiper-rtl .swiper-button-next::after,
#places .banner-margin .slider-full .swiper-button-next::after,
#places .banner-margin .slider-full .swiper-rtl .swiper-button-prev::after {
  display: none;
}
#places .banner-margin .slider-full .swiper-button-prev {
  left: inherit;
  top: inherit;
  bottom: 200px;
  right: 360px;
}
@media (max-width: 991px) {
  #places .banner-margin .slider-full .swiper-button-prev {
    bottom: 86px;
    right: 97px;
  }
}
#places .banner-margin .slider-full .swiper-button-next {
  right: 300px;
  top: inherit;
  bottom: 200px;
}
@media (max-width: 991px) {
  #places .banner-margin .slider-full .swiper-button-next {
    bottom: 86px;
    right: 27px;
  }
}
#places .banner-margin .slider-full .swiper {
  height: 95dvh;
}
@media (max-width: 991px) {
  #places .banner-margin .slider-full .swiper {
    height: 100%;
  }
}
#places .banner-margin .slider-full .swiper-slide {
  position: relative;
}
#places .banner-margin .slider-full .swiper-slide::after {
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
}
#places .banner-margin .slider-full img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
#places .banner-margin .title {
  color: var(--white, #FFF);
  font-family: "termina", sans-serif;
  font-size: 44px;
  font-style: normal;
  font-weight: 400;
  line-height: 58px;
  /* 131.818% */
  letter-spacing: 17.6px;
  margin-bottom: 15px;
}
#places .banner-margin .title .line {
  justify-content: start;
}
#places .banner-margin .subtitle {
  color: var(--white, #FFF);
  font-family: "termina", sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 38px;
  /* 135.714% */
  letter-spacing: 11.2px;
  text-transform: uppercase;
  margin-bottom: 17px;
}
#places .banner-margin .text {
  color: var(--white, #FFF);
  font-family: "Playfair";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  /* 150% */
}
#places .banner-margin .btn-down2 {
  cursor: pointer;
  width: 76px;
  height: 76px;
  background: url("../img/seta.svg") center center no-repeat;
  background-size: contain;
  overflow: hidden;
  display: block;
  margin-top: 10dvh;
  margin-left: 12px;
}
@media (max-width: 991px) {
  #places .banner-margin .btn-down2 {
    margin-top: 30px;
    margin-bottom: 50px;
    position: relative;
  }
}
#places .banner-margin .ca3-scroll-down-arrow {
  background-image: url("../img/seta-home.png");
  background-size: contain;
  background-repeat: no-repeat;
}
#places .banner-margin .ca3-scroll-down-link {
  cursor: pointer;
  height: 30px;
  width: 15px;
  position: absolute;
  left: 0;
  right: 0;
  top: 20px;
  margin: 0 auto;
  z-index: 100;
  -webkit-animation: ca3_fade_move_down 1s ease-in-out infinite;
  -moz-animation: ca3_fade_move_down 1s ease-in-out infinite;
  animation: ca3_fade_move_down 1s ease-in-out infinite;
}
@-webkit-keyframes ca3_fade_move_down {
  0% {
    -webkit-transform: translate(0, -20px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translate(0, 20px);
    opacity: 0;
  }
}
@-moz-keyframes ca3_fade_move_down {
  0% {
    -moz-transform: translate(0, -20px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    -moz-transform: translate(0, 20px);
    opacity: 0;
  }
}
@keyframes ca3_fade_move_down {
  0% {
    transform: translate(0, -20px);
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    transform: translate(0, 20px);
    opacity: 0;
  }
}
#places .banner-margin .content {
  padding-top: 36%;
  position: relative;
  z-index: 1;
}
@media (max-width: 991px) {
  #places .banner-margin .content {
    padding-top: 50%;
    padding-left: 15px;
    padding-right: 15px;
  }
}
#places .banner-margin #breadcrumb ul {
  margin-bottom: 10dvh;
}
@media (max-width: 991px) {
  #places .banner-margin #breadcrumb ul {
    margin-bottom: 50px;
  }
}
#places .lugar {
  padding-top: 150px;
  position: relative;
}
@media (max-width: 991px) {
  #places .lugar {
    padding-top: 50px;
  }
}
#places .lugar .progress-bar {
  --progress: 0;
  width: 80%;
  height: 2px;
  background-color: #CC9C4A;
  border-radius: 2px;
  overflow: hidden;
  position: absolute;
  bottom: 30px;
  left: 10%;
  z-index: 9;
  transform: matrix(-1, 0, 0, -1, 0, 0);
}
#places .lugar .inside-bar {
  transform-origin: left center;
  border-radius: 2px;
  background-color: #fff;
  height: 2px;
  transform: scaleX(var(--progress));
}
#places .lugar .nav-lugar {
  position: absolute;
  top: 150px;
  left: 10%;
  display: flex;
  gap: 64px;
  flex-direction: column;
  opacity: 0;
}
@media (max-width: 1399px) {
  #places .lugar .nav-lugar {
    left: 2%;
  }
}
@media (max-width: 991px) {
  #places .lugar .nav-lugar {
    display: none;
  }
}
#places .lugar .nav-lugar--title {
  color: var(--accent-color, #CC9C4A);
  font-family: "Playfair";
  font-size: 25px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  /* 96% */
  position: relative;
  max-width: 50%;
  text-decoration: none;
  transition: all 0.5s ease-in-out;
}
#places .lugar .nav-lugar--title::after {
  content: "";
  position: absolute;
  left: -12vw;
  bottom: -32px;
  width: 20vw;
  background: rgb(204, 156, 74);
  background: linear-gradient(90deg, rgb(204, 156, 74) 55%, rgb(0, 54, 68) 55%);
  height: 2px;
  transform-origin: left center;
  transform: scaleX(0);
  transition: all 0.5s ease-in-out;
}
#places .lugar .nav-lugar--title.active {
  color: #003644;
}
#places .lugar .nav-lugar--title.active::after {
  transform: scaleX(1);
}
#places .lugar .grid-lugar {
  display: grid;
  grid-template-columns: 602px 537px;
  gap: 50px;
  margin-bottom: 150px;
}
@media (max-width: 1550px) {
  #places .lugar .grid-lugar {
    grid-template-columns: 500px 400px;
  }
}
@media (max-width: 991px) {
  #places .lugar .grid-lugar {
    display: block;
    margin-bottom: 50px;
  }
}
#places .lugar .subtitle {
  color: #0A0A0A;
  /* desktop/Legend */
  font-family: "termina", sans-serif;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  /* 250% */
  letter-spacing: 4.8px;
}
@media (max-width: 991px) {
  #places .lugar .subtitle {
    text-align: center;
    font-size: 10px;
    line-height: 21px;
    letter-spacing: 4px;
    text-align: center;
  }
}
#places .lugar .title {
  color: var(--accent-color, #CC9C4A);
  /* desktop/H3 - Title */
  font-family: "Playfair";
  font-size: 45px;
  font-style: normal;
  font-weight: 500;
  line-height: 40px;
  /* 88.889% */
  margin-bottom: 40px;
  margin-top: 15px;
}
#places .lugar .title .line {
  justify-content: start;
}
@media (max-width: 991px) {
  #places .lugar .title {
    text-align: center;
    font-size: 32px;
    line-height: 28px;
  }
  #places .lugar .title .line {
    justify-content: center;
  }
}
#places .lugar .text {
  color: var(--Black, #0A0A0A);
  /* desktop/H6 - textBody */
  font-family: "Playfair";
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  /* 150% */
  padding-left: 65px;
}
@media (max-width: 991px) {
  #places .lugar .text {
    font-size: 14px;
    line-height: 21px;
    padding-left: 0;
    text-align: center;
  }
}
#places .lugar .title-place {
  color: var(--Black, #0A0A0A);
  /* desktop/H4 - Subtitle */
  font-family: "Playfair";
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  /* 66.667% */
  margin-bottom: 30px;
  padding-left: 65px;
}
@media (max-width: 991px) {
  #places .lugar .title-place {
    text-align: center;
    font-size: 21px;
    line-height: 14px;
    padding-left: 0px;
  }
}
#places .lugar .main-box .slider-component {
  width: 100%;
  height: 503px;
  margin-top: 119px;
  margin-bottom: 80px;
}
@media (max-width: 991px) {
  #places .lugar .main-box .slider-component {
    margin-bottom: 50px;
    height: 309px;
  }
  #places .lugar .main-box .slider-component img {
    width: 100%;
  }
}
#places .lugar .secondry-box .slider-component {
  height: 546px;
  width: 100%;
  margin-bottom: 115px;
}
@media (max-width: 991px) {
  #places .lugar .secondry-box .slider-component {
    margin-bottom: 50px;
    height: 309px;
  }
  #places .lugar .secondry-box .slider-component img {
    width: 100%;
  }
}
#places .banner-paralax {
  height: 545px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  position: relative;
}
#places .banner-paralax::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.3;
}
#places .banner-paralax .btn2 {
  width: fit-content;
}
#places .banner-paralax .paralax-content {
  max-width: 730px;
  display: flex;
  flex-direction: column;
  gap: 40px;
  align-items: center;
  position: relative;
  z-index: 9;
}
#places .banner-paralax .paralax-content .btn2 span {
  color: #ffffff;
}
#places .banner-paralax .paralax-content .btn2-4 span:hover {
  color: #CC9C4A;
}
#places .banner-paralax .title {
  color: #FFF;
  text-align: center;
  /* desktop/H1 - Title */
  font-family: Playfair;
  font-size: 80px;
  font-style: normal;
  font-weight: 300;
  line-height: 65px;
  /* 81.25% */
}
#places .banner-paralax .title strong {
  color: #CC9C4A;
}
#places .banner-paralax .text {
  color: #FFF;
  text-align: center;
  font-family: Playfair;
  font-size: 30px;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
  /* 100% */
}
#places .banner-paralax .btn-find {
  font-size: 13px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 230.769% */
  letter-spacing: 3.9px;
  color: #fff;
  text-transform: uppercase;
  text-decoration: none;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-inline: 1.5rem;
  position: relative;
  font-family: "Termina Test Demi";
  width: fit-content;
  margin: 0 auto;
}
#places .banner-paralax .btn-find:hover::after {
  width: 100%;
}
#places .banner-paralax .btn-find::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 40%;
  height: 1px;
  background: #CC9C4A;
  transition: all 0.2s ease-in;
}