#page404{
    min-height: 85dvh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 150px;
    background: url('../img/purpose/bg-proposito2.webp') bottom center no-repeat;
    img{
        aspect-ratio: 1/1;
        max-width: 100%;
    }
    h2{
        color: #004054;
        text-align: start;
        font-family: "Playfair";
        font-size: 45px;
        font-style: normal;
        font-weight: 500;
        line-height: 45px;
        margin-bottom: 30px;
    }
    .text{
        color: #000;
        text-align: start;
        font-family: "Playfair";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
    }
    .line{
        justify-content: flex-start;
    }
}
