#who {
    --progress: 0;
    padding: 248px 0 0 0;

    h1 {
        font-family: "termina", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 38px;
        line-height: 57px;
        letter-spacing: 15.2px;
        margin-left: 12px;
        color: #CC9C4A;
        .line{
            justify-content: flex-start;
        }
        @media(max-width: 991px) {
            font-size: 26px;
            text-align: center;
            line-height: 35px;
            margin-bottom: 32px;
            .line{
                justify-content: center;
            }
        }
    }

    p {
        color: #000;
        font-family: Playfair;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
        margin-bottom: 20px;

        @media(max-width: 991px) {
            font-size: 14px;
            text-align: center;
            line-height: 21px;
            margin-bottom: 12px;
        }
    }

    .subtitle {
        color: #0A0A0A;
        font-family: "termina", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 4.8px;
        text-transform: uppercase;

        @media(max-width: 991px) {
            font-size: 10px;
            line-height: 21px;
            text-align: center;
        }
    }

    .title-section {
        color: #CC9C4A;
        font-family: Playfair;
        font-size: 45px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px;

        @media(max-width: 991px) {
            font-size: 32px;
            line-height: 28px;
            text-align: center;
        }
    }

    .section1 {
        padding-bottom: 132px;

        @media(max-width: 991px) {
            padding-bottom: 60px;
        }
    }

    .section2 {

        .video {
            position: relative;
            width: 97%;
            height: 823px;
            margin: 0 auto;
            display: flex;

            @media(max-width: 991px) {
                width: 100%;
                height: 574px;
            }

            video {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }

            .controls {
                display: flex;

                .play {
                    box-sizing: border-box;
                    width: 0;
                    height: 15px;
                    border-style: solid;
                    border-width: 9px 0 9px 10px;
                    border-color: transparent transparent transparent #ffffff;
                    cursor: pointer;
                    will-change: border-width;
                    transition: all 0.2s ease;
                    z-index: 999;
                    position: absolute;
                    left: 30.8%;
                    bottom: 8.8%;
                    opacity: 1;

                    @media (max-width: 991px) {
                        right: 0;
                        left: 5%;
                    }

                    &.paused {
                        border-style: double;
                        border-width: 0px 0 0px 10px;
                    }

                    &:hover {
                        border-color: transparent transparent transparent #ffffff;
                    }
                }

                .speaker {
                    height: 30px;
                    width: 30px;
                    right: 30.5%;
                    bottom: 7.5%;
                    position: absolute;
                    overflow: hidden;
                    display: inline-block;
                    z-index: 999;
                    opacity: 1;

                    @media (max-width: 991px) {
                        left: 9.2% !important;
                    }

                    @media (min-width: 1800px) {
                        height: 33px;
                    }

                    span {
                        display: block;
                        width: 8px;
                        height: 8px;
                        background: #fff;
                        margin: 11px 0 0 2px;

                        &:after {
                            content: "";
                            position: absolute;
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-color: transparent #fff transparent transparent;
                            border-width: 8px 15px 8px 14px;
                            left: -12px;
                            top: 7px;
                        }

                        &:before {
                            transform: rotate(45deg);
                            border-radius: 0 50px 0 0;
                            content: "";
                            position: absolute;
                            width: 10px;
                            height: 10px;
                            border-style: double;
                            border-color: #fff;
                            border-width: 7px 7px 0 0;
                            left: 20px;
                            top: 9px;
                            transition: all 0.2s ease-out;
                        }
                    }

                    &:hover {
                        span:before {
                            transform: scale(0.8) translate(-3px, 0) rotate(42deg);
                        }
                    }

                    &.mute {
                        span:before {
                            transform: scale(0.5) translate(-15px, 0) rotate(36deg);
                            opacity: 0;
                        }
                    }
                }

                .progress-bar {
                    position: absolute;
                    width: 34%;
                    height: 2px;
                    background-color: #fff;
                    bottom: 9.5%;
                    left: 0;
                    right: 0;
                    margin: 0 auto;

                    .progress {
                        height: 100%;
                        width: 0;
                        background-color: #CC9C4A;
                    }
                }
            }
        }
    }

    .section3 {
        padding: 152px 0 147px 0;

        @media(max-width: 991px) {
            padding: 80px 0 56px 0;
        }

        .subtitle {
            margin-left: 12px;
            margin-bottom: 11px;

            @media(max-width: 991px) {
                margin-left: 0;
            }
        }

        .title-section {
            margin-left: 18px;
            margin-bottom: 44px;
            .line{
                justify-content: flex-start;
            }
            @media(max-width: 991px) {
                margin-left: 0;
                .line{
                    justify-content: center;
                }
            }
        }

        p {
            margin-left: 77px;
            max-width: 553px;

            @media(max-width: 991px) {
                margin-left: 0;
            }
        }

        .galeria {
            width: 682px;
            height: 548px;
            position: absolute;
            right: -89px;

            @media(max-width: 1500px) {
                width: 100%;
            }

            @media(max-width: 991px) {
                position: inherit;
                right: 0;
                height: 344px;
                margin-top: 56px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .slider-component {
                aspect-ratio: 1.2;
                position: relative;
                margin-bottom: 73px;

                img {
                    width: 100%;
                    object-fit: cover;
                }

                .progress-bar {
                    width: 80%;
                    height: 2px;
                    background-color: #CC9C4A;
                    border-radius: 2px;
                    overflow: hidden;
                    position: absolute;
                    bottom: 30px;
                    left: 10%;
                    z-index: 9;
                    transform: matrix(-1, 0, 0, -1, 0, 0);
                }

                .inside-bar {
                    transform-origin: left center;
                    border-radius: 2px;
                    background-color: #fff;
                    height: 2px;
                    transform: scaleX(var(--progress));
                }


            }

            .content--image {
                display: flex;
                width: 100%;
                height: 548px;
                overflow: hidden;
                position: relative;

                @media(max-width: 991px) {
                    height: 344px;
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    inset: 0;
                }
            }
        }
    }

    .section4 {
        padding-bottom: 211px;

        @media(max-width: 991px) {
            padding-bottom: 0px;

            .row {
                flex-direction: column-reverse;
            }
        }

        .subtitle {
            margin-top: 196px;
            margin-left: 29px;
            margin-bottom: 12px;

            @media(max-width: 991px) {
                margin-top: 0;
                margin-left: 0;
            }
        }

        .title-section {
            margin-left: 31px;
            max-width: 439px;
            margin-bottom: 36px;
            .line{
                justify-content: flex-start;
            }
            @media(max-width: 991px) {
                margin-left: 0;
                .line{
                    justify-content: center;
                }
            }
        }

        p {
            margin-left: 96px;

            @media(max-width: 991px) {
                margin-left: 0;
            }
        }

        .galeria {
            width: 694px;
            height: 733px;
            position: absolute;
            left: -99px;

            @media(max-width: 1500px) {
                width: 100%;
            }

            @media(max-width: 991px) {
                position: inherit;
                left: 0;
                height: 618px;
                margin-top: 56px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .slider-component {
                aspect-ratio: 1.2;
                position: relative;
                margin-bottom: 73px;

                img {
                    width: 100%;
                    object-fit: cover;
                }

                .progress-bar {
                    width: 80%;
                    height: 2px;
                    background-color: #CC9C4A;
                    border-radius: 2px;
                    overflow: hidden;
                    position: absolute;
                    bottom: 30px;
                    left: 10%;
                    z-index: 9;
                    transform: matrix(-1, 0, 0, -1, 0, 0);
                }

                .inside-bar {
                    transform-origin: left center;
                    border-radius: 2px;
                    background-color: #fff;
                    height: 2px;
                    transform: scaleX(var(--progress));
                }


            }

            .content--image {
                display: flex;
                width: 100%;
                height: 733px;
                overflow: hidden;
                position: relative;

                @media(max-width: 991px) {
                    height: 618px;
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    inset: 0;
                }

                .image-wrap {
                    width: 100% !important;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    .section5 {
        padding: 119px 0 238px 0;
        background: url('../img/who/bg-section5.webp');
        background-size: contain;
        background-repeat: no-repeat;
        background-color: #001F27;

        @media(max-width: 991px) {
            padding: 60px 0 104px 0;
        }

        .subtitle {
            color: #CC9C4A;
            margin-left: 15px;
            margin-bottom: 12px;

            @media(max-width: 991px) {
                margin-left: 0;
            }
        }

        .title-section,
        p {
            color: #ffffff;
        }

        .title-section {
            margin-left: 15px;
            max-width: 733px;
            margin-bottom: 48px;
            .line{
                justify-content: flex-start;
            }
            @media(max-width: 991px) {
                margin-left: 0;
                .line{
                    justify-content: center;
                }
            }
        }

        p {
            margin-left: 79px;
            max-width: 555px;

            @media(max-width: 991px) {
                margin-left: 0;
            }
        }

        .galeria {
            width: 520px;
            height: 576px;
            position: absolute;
            left: 11px;
            top: 42px;

            @media(max-width: 1500px) {
                width: 100%;
            }

            @media(max-width: 991px) {
                position: inherit;
                left: 0;
                height: 474px;
                margin-top: 0px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }

            .slider-component {
                aspect-ratio: 1.2;
                position: relative;
                margin-bottom: 73px;

                img {
                    width: 100%;
                    object-fit: cover;
                }

                .progress-bar {
                    width: 80%;
                    height: 2px;
                    background-color: #CC9C4A;
                    border-radius: 2px;
                    overflow: hidden;
                    position: absolute;
                    bottom: 30px;
                    left: 10%;
                    z-index: 9;
                    transform: matrix(-1, 0, 0, -1, 0, 0);
                }

                .inside-bar {
                    transform-origin: left center;
                    border-radius: 2px;
                    background-color: #fff;
                    height: 2px;
                    transform: scaleX(var(--progress));
                }


            }

            .content--image {
                display: flex;
                width: 100%;
                height: 576px;
                overflow: hidden;
                position: relative;

                @media(max-width: 991px) {
                    height: 474px;
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                    position: absolute;
                    inset: 0;
                }

                .image-wrap {
                    width: 100% !important;
                    height: 100%;
                    object-fit: cover;
                }
            }
        }
    }

    .section6 {
        padding: 160px 0 163px 0;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;

        @media(max-width: 991px) {
            padding: 100px 0;
        }

        .container {
            z-index: 2;
            position: relative;
        }

        .subtitle,
        .title-section {
            color: #fff;
            text-align: center;
        }

        .subtitle {
            margin-bottom: 12px;
        }

        .title-section {
            font-size: 80px;
            line-height: 65px;
            margin-bottom: 86px;
            .line{
                justify-content: center;
            }
            @media(max-width: 991px) {
                font-size: 56px;
                line-height: 50px;
                margin-bottom: 56px;
            }
        }

        .btn-section {
            display: inline-block;
            text-decoration: none;
            position: relative;
            font-family: "termina", sans-serif;
            font-weight: 400;
            font-style: normal;
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
            letter-spacing: 3.9px;
            color: #ffffff;
            text-transform: uppercase;

            &::before {
                position: absolute;
                bottom: -13px;
                left: -27px;
                content: "";
                width: 121px;
                height: 2.5px;
                background-color: #CC9C4A;
            }
        }

        .btn2 span {
            color: #ffffff;
        }

        .btn2-4 span:hover {
            color: #CC9C4A;
        }

        .layer {
            position: absolute;
            top: 0;
            width: 100%;
            height: 100%;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: 1;
        }
    }
}
