#contact {
    padding: 247px 0 0 0;

    h1 {
        color: #CC9C4A;
        font-family: "termina", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 44px;
        font-style: normal;
        font-weight: 400;
        line-height: 66px;
        letter-spacing: 17.6px;
        margin-bottom: 51px;
        .line{
            justify-content: start;

        }
        @media(max-width: 991px) {
            font-size: 26px;
            text-align: center;
            line-height: 35px;
            margin-bottom: 32px;
            .line{
                justify-content: center;
            }
        }
    }

    p {
        color: #000;
        font-family: Playfair;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
        margin-bottom: 20px;

        @media(max-width: 991px) {
            font-size: 14px;
            text-align: center;
            line-height: 21px;
            margin-bottom: 12px;
        }
    }

    .subtitle {
        color: #0A0A0A;
        font-family: "termina", sans-serif;
        font-weight: 400;
        font-style: normal;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        letter-spacing: 4.8px;
        text-transform: uppercase;

        @media(max-width: 991px) {
            font-size: 10px;
            line-height: 21px;
            text-align: center;
        }
    }

    .title-section {
        color: #CC9C4A;
        font-family: Playfair;
        font-size: 45px;
        font-style: normal;
        font-weight: 500;
        line-height: 40px;
        .line{
            justify-content: flex-start;
        }
        @media(max-width: 991px) {
            font-size: 32px;
            line-height: 28px;
            text-align: center;
            .line{
                justify-content: center;
            }
        }
    }

    .section1 {
        padding-bottom: 308px;

        @media(max-width: 991px) {
            padding-bottom: 19px;
        }

        p {
            margin-left: 12px;
            max-width: 500px;

            @media(max-width: 991px) {
                margin-left: 0;
            }
        }

        .redes {
            margin-top: 40px;
            margin-left: 12px;
            display: flex;
            gap: 18px;

            @media(max-width: 991px) {
                justify-content: center;
                margin-top: 32px;
            }

            a {
                display: inline-block;
                text-decoration: none;
            }
        }

        .video-contato {
            width: 870px;
            height: 521px;
            position: absolute;
            right: 0;

            @media(max-width: 1500px) {
                width: 50%;
            }

            @media(max-width: 991px) {
                width: 100%;
                position: relative;
                margin-top: 70px;
            }
        }

        .video {
            position: relative;
            width: 100%;
            height: 100%;
            margin: 0 auto;
            display: flex;

            video {
                object-fit: cover;
                width: 100%;
                height: 100%;
            }

            .controls {
                display: flex;

                .play {
                    box-sizing: border-box;
                    width: 0;
                    height: 15px;
                    border-style: solid;
                    border-width: 9px 0 9px 10px;
                    border-color: transparent transparent transparent #ffffff;
                    cursor: pointer;
                    will-change: border-width;
                    transition: all 0.2s ease;
                    z-index: 999;
                    position: absolute;
                    left: 30.8%;
                    bottom: 7.8%;
                    opacity: 1;

                    @media (max-width: 991px) {
                        right: 0;
                        left: 5%;
                    }

                    &.paused {
                        border-style: double;
                        border-width: 0px 0 0px 10px;
                    }

                    &:hover {
                        border-color: transparent transparent transparent #ffffff;
                    }
                }

                .speaker {
                    height: 30px;
                    width: 30px;
                    right: 28.5%;
                    bottom: 6.5%;
                    position: absolute;
                    overflow: hidden;
                    display: inline-block;
                    z-index: 999;
                    opacity: 1;

                    @media (max-width: 991px) {
                        left: 9.2% !important;
                    }

                    @media (min-width: 1800px) {
                        height: 33px;
                    }

                    span {
                        display: block;
                        width: 8px;
                        height: 8px;
                        background: #fff;
                        margin: 11px 0 0 2px;

                        &:after {
                            content: "";
                            position: absolute;
                            width: 0;
                            height: 0;
                            border-style: solid;
                            border-color: transparent #fff transparent transparent;
                            border-width: 8px 15px 8px 14px;
                            left: -12px;
                            top: 7px;
                        }

                        &:before {
                            transform: rotate(45deg);
                            border-radius: 0 50px 0 0;
                            content: "";
                            position: absolute;
                            width: 10px;
                            height: 10px;
                            border-style: double;
                            border-color: #fff;
                            border-width: 7px 7px 0 0;
                            left: 20px;
                            top: 9px;
                            transition: all 0.2s ease-out;
                        }
                    }

                    &:hover {
                        span:before {
                            transform: scale(0.8) translate(-3px, 0) rotate(42deg);
                        }
                    }

                    &.mute {
                        span:before {
                            transform: scale(0.5) translate(-15px, 0) rotate(36deg);
                            opacity: 0;
                        }
                    }
                }

                .progress-bar {
                    position: absolute;
                    width: 34%;
                    height: 2px;
                    background-color: #fff;
                    bottom: 9.5%;
                    left: 0;
                    right: 0;
                    margin: 0 auto;

                    .progress {
                        height: 100%;
                        width: 0;
                        background-color: #CC9C4A;
                    }
                }
            }
        }
    }

    .section2, a {
        color: #0A0A0A;
        font-family: Playfair;
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
        padding-bottom: 61px;
        text-decoration: none;
        .subtitle {
            margin-bottom: 12px;
            margin-left: 12px;

            @media(max-width: 991px) {
                margin-left: 0;
                margin-top: 30px;
            }
        }

        .title-section {
            margin-left: 12px;
            margin-bottom: 42px;

            @media(max-width: 991px) {
                margin-left: 0;
            }
        }

        .e-mail,
        .telefone,
        .endereco {
            display: inherit;
            align-items: center;
            margin-left: 29px;
            margin-bottom: 21px;

            @media(max-width: 991px) {
                text-align: center;
                margin-left: 0px;
            }

            svg {
                margin-right: 20px;

                @media(max-width: 991px) {
                    width: 90%;
                }
            }
        }
    }

    .section3 {
        position: relative;
        margin-top: 226px;
        padding: 481px 0 165px 0;

        background-image: url('../img/contact/textura002.webp');
        background-size: contain;
        background-repeat: no-repeat;
        background-color: #001F27;
        background-position: bottom;

        @media(max-width: 991px) {
            padding: 190px 0 165px 0;
        }

        .mapa {
            width: 80%;
            height: 593px;
            position: absolute;
            top: -114px;
            left: 0;
            right: 0;
            margin: 0 auto;

            @media(max-width: 991px) {
                width: 100%;
            }
        }

        #mapa {
            width: 100%;
            height: 593px;

            @media (max-width: 1100px) {
                height: 331px;
            }
        }

        .subtitle {
            color: #ffffff;
            margin-left: 12px;
            margin-bottom: 11px;

            @media(max-width: 991px) {
                margin-left: 0;
            }
        }

        .title-section {
            margin-left: 13px;
            margin-bottom: 38px;

            @media(max-width: 991px) {
                margin-left: 0;
            }
        }

        p {
            color: #ffffff;
            margin-left: 77px;
            max-width: 500px;

            @media(max-width: 991px) {
                margin-left: 0;
            }
        }

        form {
            margin-top: 75px;
            margin-left: 78px;

            @media(max-width: 991px) {
                margin-left: 0px;
            }

            label,
            input {
                width: 100%;
                max-width: 500px;
            }

            textarea {
                width: 100%;
                max-width: 595px;
            }

            label {
                color: #CC9C4A;
                font-family: "termina", sans-serif;
                font-weight: 400;
                font-style: normal;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 25px;
                margin-bottom: 12px;
            }

            textarea,
            input {
                padding: 15px 32px;
                color: rgba(255, 255, 255, 0.60);
                font-family: "termina", sans-serif;
                font-weight: 400;
                font-style: normal;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: 25px;
                margin-bottom: 20px;
                border: 1px solid #FFF;
                background-color: transparent;

                &::placeholder {
                    color: rgba(255, 255, 255, 0.60);
                    font-family: "termina", sans-serif;
                    font-weight: 400;
                    font-style: normal;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 25px;
                }
            }

            textarea {
                min-height: 160px;
            }

            input[type="checkbox"] {
                width: 20px;
                background-color: transparent;
                border: 1px solid var(--accent-color, #CC9C4A);
                padding: 0;
                margin: 0;
            }

            .termos {
                display: flex;
                margin-top: 48px;
                gap: 20px;

                @media(max-width: 991px) {
                    margin-top: 20px;
                    margin-bottom: 56px;
                }

                p {
                    padding: 0;
                    margin: 0;
                    color: #FFF;
                    font-family: "termina", sans-serif;
                    font-weight: 400;
                    font-style: normal;
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 25px;

                    a {
                        color: #FFF;
                        font-family: "termina", sans-serif;
                        font-weight: 400;
                        font-style: normal;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 25px;
                    }
                }
            }

            input[type="submit"] {
                max-width: 150px;
                color: #FFF;
                text-align: center;
                font-family: "termina", sans-serif;
                font-weight: 400;
                font-style: normal;
                font-size: 13px;
                font-style: normal;
                font-weight: 600;
                line-height: 30px;
                letter-spacing: 3.9px;
                text-transform: uppercase;
                border: none;
                position: relative;
                margin-top: 35px;
            }

            .btn2 span {
                color: #ffffff;
            }

            .btn2-4 span:hover {
                color: #CC9C4A;
            }
        }
    }
}
