
.scroll {
    width: 100%;
}

.text-marquee {
    position: relative;
    display: flex;
    align-items: center;
    overflow-x: hidden;
    z-index: 50;
    .text-single {
        position: relative;
        white-space: nowrap;
        padding: 0;
        margin: 0;
        will-change: transform;
        overflow: hidden;
    }

    .text {
        padding: 0;
        display: inline-block;
        margin-right: 2rem;
        @include fontTemplate(90px, 1, #CC9C4A, 500, 'Playfair', left);
    }

    @media (max-width: 999px) {
        .text {
            color: var(--accent-color, #CC9C4A);
            text-align: center;
            font-size: 63px;
            font-style: normal;
            font-weight: 500;
            line-height: 1;
        }
    }
}
