#privacy{
    min-height: 85dvh;
    padding-top: 25vh;
    .title{
        color: var(--Black, #0A0A0A);
        text-align: start;
        font-family: "Playfair";
        font-size: 45px;
        font-style: normal;
        font-weight: 500;
        line-height: 45px;
        margin-bottom: 30px;
    }
    .subtitle{
        color: #004054;
        text-align: start;
        font-family: "Playfair";
        font-size: 30px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 30px;
        padding-left: 5%;
    }
    .text{
        color: #000;
        text-align: start;
        font-family: "Playfair";
        font-size: 20px;
        font-style: normal;
        font-weight: 500;
        line-height: 30px;
        padding-left: 5%;
    }
}
