#home {
    --progress: 0;
    @media (max-width: 991px) {
        overflow-x: hidden;
    }
    // background: url('../img/Home.png') no-repeat;
    .banner-gif {
        height: 100dvh;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        flex-direction: column;
        gap: 30px;
        top: -5px;
        cursor: none;
        @media (max-width: 991px) {
            cursor: auto;

        }
        &::after{
            content: '';
            position: absolute;
            bottom: -80px;
            left: 50%;
            height: 160px;
            width: 1px;
            background: #CC9C4A;
        }
        &::before{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100.5%;
            background: #0000003e;
        }
        .btn2{
            margin-bottom: 4rem;
        }
        .destaque-video {
            position: absolute;
            z-index: -1;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        .text-destaque {
            font-family: 'Playfair Display', serif;
            color: #fff;
            font-size: 80px;
            font-style: normal;
            font-weight: 300;
            line-height: 90px;
            /* 81.25% */
            text-align: center;
            @media (max-width: 991px) {
                color: #FFF;
                text-align: center;
                /* H1 - Title MOBILE */
                font-family: 'Playfair';
                font-size: 56px;
                font-style: normal;
                font-weight: 300;
                line-height: 50px; /* 89.286% */
                br{
                    display: none;
                }
            }
        }

        .btn-find {
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
            /* 230.769% */
            letter-spacing: 3.9px;
            color: #fff;
            text-transform: uppercase;
            text-decoration: none;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-inline: 1.5rem;
            position: relative;
            font-family: "termina", sans-serif;

            &:hover {
                &::after {
                    width: 100%;
                }
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 40%;
                height: 1px;
                background: #CC9C4A;
                transition: all .2s ease-in;
            }
        }

        .btn-down {
            cursor: pointer;
            position: absolute;
            bottom: 70px;
            left: 20vw;
        }

        .btn-down2 {
            cursor: pointer;
            position: absolute;
            width: 76px;
            height: 76px;
            bottom: 77px;
            left: 16.6vw;
            background: url('../img/seta.svg') center center no-repeat;
            background-size: contain;
            overflow: hidden;
            @media (max-width: 991px) {
                width: 53px;
                height: 53px;
                bottom: 56px;
                left: 23px;
            }
        }

        .ca3-scroll-down-arrow {
            background-image: url('../img/seta-home.png');
            background-size: contain;
            background-repeat: no-repeat;
        }

        .ca3-scroll-down-link {
            cursor: pointer;
            height: 30px;
            width: 15px;
            position: absolute;
            left: 0;
            right: 0;
            top: 20px;
            margin: 0 auto;
            z-index: 100;

            -webkit-animation: ca3_fade_move_down 1s ease-in-out infinite;
            -moz-animation: ca3_fade_move_down 1s ease-in-out infinite;
            animation: ca3_fade_move_down 1s ease-in-out infinite;
        }


        /*animated scroll arrow animation*/
        @-webkit-keyframes ca3_fade_move_down {
            0% {
                -webkit-transform: translate(0, -20px);
                opacity: 0;
            }

            50% {
                opacity: 1;
            }

            100% {
                -webkit-transform: translate(0, 20px);
                opacity: 0;
            }
        }

        @-moz-keyframes ca3_fade_move_down {
            0% {
                -moz-transform: translate(0, -20px);
                opacity: 0;
            }

            50% {
                opacity: 1;
            }

            100% {
                -moz-transform: translate(0, 20px);
                opacity: 0;
            }
        }

        @keyframes ca3_fade_move_down {
            0% {
                transform: translate(0, -20px);
                opacity: 0;
            }

            50% {
                opacity: 1;
            }

            100% {
                transform: translate(0, 20px);
                opacity: 0;
            }
        }

        .btn2 span {
            color: #ffffff;
            @media (max-width: 991px) {
                color: var(--white, #FFF);
                text-align: center;
                /* btn - MOBILE */
                font-family: "termina", sans-serif;
                font-size: 10px;
                font-style: normal;
                font-weight: 600;
                line-height: 21px; /* 210% */
                letter-spacing: 3px;
            }
        }

        .btn2-4 span:hover {
            color: #CC9C4A;
        }
        //ball
        .ball {
            width: 128px;
            height: 128px;
            position: fixed;
            top: 0;
            left: 0;
            border: 1px solid #CC9C4A;
            border-radius: 50%;
            pointer-events: none;
            z-index: 9999;
            -webkit-backdrop-filter: blur(10px);
            backdrop-filter: blur(10px);
            background-color: rgba(0, 0, 0, 0.1294117647);
            display: flex;
            justify-content: center;
            align-items: center;
            display: none;
            @media (max-width: 991px) {
                display: none;
            }
        }
    }

    .quem-somos {
        padding-top: 130px;
        background: #fff;
        padding-bottom: 78px;
        @media (max-width: 991px) {
            padding-top: 74px;
            padding-bottom: 0;
        }
        .container {
            max-width: 1426px;
            margin: 0 auto;
            @media (max-width: 1399px) {
                max-width: 1280px;
            }
            @media (max-width: 991px) {
                padding: 0;
            }
        }

        .title-box {
            display: flex;
            flex-direction: column;
            gap: 50px;
            width: 80%;
            margin: 0 auto;
            padding-bottom: 130px;
            align-items: center;
            @media (max-width: 991px) {
                width: 100%;
                gap: 40px;
                padding-bottom: 87px;
            }
        }

        .btn-find {
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
            /* 230.769% */
            letter-spacing: 3.9px;
            color: #0A0A0A;
            text-transform: uppercase;
            text-decoration: none;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-inline: 1.5rem;
            position: relative;
            font-family: "termina", sans-serif;
            width: fit-content;

            &:hover {
                &::after {
                    width: 100%;
                }
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 40%;
                height: 1px;
                background: #CC9C4A;
                transition: all .2s ease-in;
            }
        }

        .title {
            color: var(--Black, #0A0A0A);
            text-align: center;
            /* desktop/H4 - Subtitle */
            font-family: 'Playfair';
            font-size: 30px;
            font-style: normal;
            font-weight: 500;
            line-height: 35px;
            /* 66.667% */
            max-width: 65%;

            strong {
                color: var(--accent-color, #CC9C4A);
            }
            @media (max-width: 991px) {
                color: #0A0A0A;
                text-align: center;
                font-family: 'Playfair';
                font-size: 21px;
                font-style: normal;
                font-weight: 500;
                line-height: 21px; /* 100% */
                max-width: 100%;
                padding-inline: 22px;
                br{
                    display: none;
                }
            }
        }

        .grid-content {
            width: 100%;
            display: grid;
            grid-template-columns: repeat(2, 684px);
            gap: 50px;
            padding-top: 1rem;
            @media (max-width: 1400px) {
                grid-template-columns: repeat(2, 500px);
                justify-content: center;
            }
            @media (max-width: 1100px) {
                display: flex;
                flex-direction: column;
                gap: 0;
            }
        }

        .grid-subtitle {
            color: var(--Black, #0A0A0A);
            text-align: center;
            font-family: "termina", sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 30px;
            /* 250% */
            letter-spacing: 4.8px;
            @media (max-width: 991px) {
                color: var(--Black, #0A0A0A);
                text-align: center;
                /* Legend - MOBILE */
                font-size: 10px;
                font-style: normal;
                font-weight: 400;
                line-height: 21px; /* 210% */
                letter-spacing: 4px;
            }
        }

        .grid-title {
            color: #CC9C4A;
            text-align: center;
            /* desktop/H2 - Title */
            font-family: 'Playfair';
            font-size: 45px;
            font-style: normal;
            font-weight: 500;
            line-height: 45px;
            margin-top: 30px;
            @media (max-width: 991px) {
                color: #CC9C4A;
                text-align: center;
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: 30px; /* 43.75% */
                margin-bottom: 20px;
            }
        }

        .text {
            color: var(--Black, #0A0A0A);
            text-align: center;
            /* desktop/H6 - textBody */
            font-family: 'Playfair';
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;
            /* 150% */
            max-width: 80%;
            margin: 0 auto;
            margin-bottom: 90px;
            margin-top: 20px;
            @media (max-width: 1400px) {
                br{
                    display: none;
                }
            }
            @media (max-width: 991px) {
                color: var(--Black, #0A0A0A);
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 21px; /* 150% */
                margin: 0;
                max-width: 100%;
                margin-bottom: 40px;
            }
        }

        .box-item {
            @media (max-width: 991px) {
                display: flex;
                flex-direction: column;
                .slider-component{
                    order: 3;
                }
            }
        }

        .slider-component {
            aspect-ratio: 1.2;
            position: relative;
            margin-bottom: 73px;
            img{
                width: 100%;
                object-fit: cover;
            }
            .progress-bar {
                width: 80%;
                height: 2px;
                background-color:#CC9C4A;
                border-radius: 2px;
                overflow: hidden;
                position: absolute;
                bottom: 30px;
                left: 10%;
                z-index: 9;
                transform: matrix(-1, 0, 0, -1, 0, 0);
            }

            .inside-bar {
                transform-origin: left center;
                border-radius: 2px;
                background-color: #fff;
                height: 2px;
                transform: scaleX(var(--progress));
            }


        }

        .content--image {
            display: flex;
            width: 100%;
            height: 548px;
            overflow: hidden;
            position: relative;
            @media (max-width: 991px) {
                height: 341px;
            }
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                position: absolute;
                inset: 0;
            }
        }

        .enter-image-style {
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .video-gif {
        position: relative;
        padding-bottom: 120px;

        .btn-find {
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
            /* 230.769% */
            letter-spacing: 3.9px;
            color: #0A0A0A;
            text-transform: uppercase;
            text-decoration: none;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-inline: 1.5rem;
            position: relative;
            font-family: "termina", sans-serif;
            width: fit-content;
            margin: 0 auto;

            &:hover {
                &::after {
                    width: 100%;
                }
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 40%;
                height: 1px;
                background: #CC9C4A;
                transition: all .2s ease-in;
            }
        }

        .text-destaque {
            color: #000;
            text-align: center;
            font-family: 'Playfair';
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;
            max-width: 58%;
            margin: 0 auto;
            margin-bottom: 50px;
            margin-top: 118px;
            @media (max-width: 991px) {
                margin-top: 80px;
                color: #000;
                text-align: center;
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 21px; /* 150% */
                max-width: 100%;
                padding-inline: 15px;
            }
        }

        .img-gif {
            width: 100%;
            max-height: 630px;
            object-fit: cover;
        }

        .text-marquee {
            position: absolute;
            top: 25%;
            @media (max-width: 991px) {
                top: 18%;
            }
        }

        .container {
            max-width: 1425px;
            margin: 0 auto;
        }

        .content--image {
            display: flex;
            width: 100%;
            height: 630px;
            overflow: hidden;
            position: relative;
            @media (max-width: 991px) {
                height: 275px;
            }
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                position: absolute;
                inset: 0;
            }
        }

        .enter-image-style {
            background-repeat: no-repeat;
            background-size: cover;
        }
    }

    .slider-vinhos {
        min-height: 100dvh;
        background-color: #003644;
        background-size: cover;
        display: flex;
        align-items: center;
        padding-top: 88px;
        padding-bottom: 73px;

        .swiper-slide-active {
            .bottle-destaque {
                opacity: 1;
                transform: translate(-36%, 8%);
                @media (max-width: 991px) {
                    transform: translate(0%, 0%) scale(.8);
                    gap: 32px;

                }
            }
        }

        .swiper-slide-active {
            .text-content {
                opacity: 1;
                transform: translate(0px, 0%);
            }
        }

        .swiper-button-prev,
        .swiper-rtl .swiper-button-next,
        .swiper-button-next,
        .swiper-rtl .swiper-button-prev {
            width: 120px;
            height: 120px;
            @media (max-width: 1600px) {
                transform: scale(.8);
            }
            @media (max-width: 1250px) {
                transform: scale(.7);
            }
            @media (max-width: 1100px) {
                transform: scale(1);
                width: 40px;
                height: 40px;
            }
            &::after {
                display: none;
            }
        }

        .swiper-button-prev {
            left: 38px;
            top: 35%;
            @media (max-width: 991px) {
                top: 75%;
                left: 15px;
            }
        }

        .swiper-button-next {
            right: 14px;
            top: 35%;
            filter: brightness(0) invert(1);
            @media (max-width: 991px) {
                top: 75%;
                right: 15px;
            }
        }

        .bottles-controll {
            max-width: 1320px;
            margin: 0 auto;
            display: flex;
            justify-content: space-around;
            margin-bottom: 165px;
            position: relative;
            &::after {
                content: '';
                width: 80%;
                height: 2px;
                background-color: #fff;
                position: absolute;
                bottom: -40px;
                @media (max-width: 991px) {
                    display: none;
                }
            }
            @media (max-width: 991px) {
                max-width: 100%;
                width: 100%;
                overflow-x: scroll;
                height: 100%;
                overflow-y: hidden;
                margin-bottom: 0;
                justify-content: inherit;
                margin-bottom: 100px;
                padding-bottom: 30px;
                justify-content: space-between;
            }
        }
        .ball-vinhos{
            position: absolute;
            top: 5px;
            left: 0;
            width: 14px;
            height: 14px;
            background: #fff;
            border-radius: 50%;
        }
        .nav-controlls-bullet{
            display: flex;
            position: absolute;
            width: 79%;
            bottom: -49px;
            justify-content: space-between;
            &::after{

            }
            .nav-bullet{
                height: 23px;
                width: 1px;
                background: #fff;
                &.active{
                    height: 14px;
                    width: 14px;
                    background: transparent;
                    border-radius: 50%;
                    position: relative;
                    align-items: center;
                    justify-content: center;
                    display: flex;
                    bottom: -5px;
                    &::after{
                        content: '';
                        width: 20px;
                        height: 20px;
                        border-radius: 50%;
                        background: transparent;
                        border: 1px solid #fff;
                    }
                }
            }
            @media (max-width: 991px) {
                bottom: 0;
                width: 100%;
                max-width: 100%;
                gap: 112px;
                overflow-x: scroll;
                justify-content: space-around;
                &::after{
                    position: absolute;
                    width: 100%;
                    content: '';
                    height: 2px;
                    background-color: #fff;
                    bottom: 10px;
                    left: 0;
                }
            }
        }

        .bottle-box {
            display: flex;
            flex-direction: column;
            gap: 50px;
            align-items: center;
            cursor: pointer;
            transition: all .5s ease-in-out;
            &:hover{
                transform: scale(1.2);
                gap: 20px;
            }
            @media (max-width: 991px) {
                gap: 20px;
                &:hover{
                    transform: scale(1);
                }
            }
            img {
                max-height: 102px;
                @media (max-width: 991px) {
                    max-height: 71px;
                }
            }

            h4 {
                color: #fff;
                text-align: center;
                font-family: 'Playfair';
                font-size: 25px;
                font-style: normal;
                font-weight: 500;
                line-height: 24px;
                @media (max-width: 991px) {
                    font-size: 20px;
                    line-height: 21px;
                }
            }
        }

        .container {
            max-width: 90vw;
            @media (max-width: 991px) {
                max-width: 100%;
            }
        }

        .box-content {
            display: grid;
            grid-template-columns: 450px 450px;
            justify-items: center;
            justify-content: center;
            gap: 400px;
            width: 100%;
            margin: 0 auto;
            min-height: 75dvh;
            @media (max-width: 1600px) {
                transform: scale(.8);
            }
            @media (max-width: 1250px) {
                transform: scale(.7);
            }
            @media (max-width: 1100px) {
                display: flex;
                flex-direction: column;
                transform:  scale(1);
                padding-inline: 7.5px;
                min-height: auto;
                gap: 0;
            }
        }

        .text-content {
            max-width: 450px;
            opacity: 0;
            transition: all .5s ease-in-out;
            transform: translate(0px, -5%);
            @media (max-width: 1100px) {
                min-height: 400px;
            }
        }

        .title {
            color: #FFF;
            font-family: 'Playfair';
            font-size: 45px;
            font-style: normal;
            font-weight: 500;
            line-height: 40px;
            margin-bottom: 56px;
            padding-left: 5rem;
            @media (max-width: 1100px) {
                color: #FFF;
                font-family: 'Playfair';
                font-size: 32px;
                font-style: normal;
                font-weight: 500;
                line-height: 32px;
                /* 100% */
                margin-bottom: 30px;
                padding-left: 0;
            }
        }

        .text {
            color: #FFF;
            font-family: 'Playfair';
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;
            padding-left: 26%;
            min-height: 250px;
            @media (max-width: 1100px) {
                color: #FFF;
                font-family: 'Playfair';
                font-size: 14px;
                font-style: normal;
                font-weight: 500;
                line-height: 21px;
                padding-left: 0;
                min-height: auto;
                /* 150% */
            }
        }

        .text-small {
            color: #FFF;
            font-family: "termina", sans-serif;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 25px;
            padding-left: 26%;
            position: relative;
            margin-top: 70px;

            @media (max-width: 1100px) {
                color: #FFF;
                font-family: "termina", sans-serif;
                font-size: 10px;
                font-style: normal;
                font-weight: 500;
                line-height: 21px;
                padding-left: 0;
                /* 210% */
            }
            .line-color{
                background: #fff;
                position: absolute;
                left: -100%;
                width: 200%;
                height: 1px;
                top: -35px;
            }

        }

        .bottle-destaque {
            position: relative;
            width: 100%;
            flex-direction: column;
            align-items: center;
            gap: 7rem;
            opacity: 0;
            transform: translate(-36%, 0%);
            display: flex;

            h3 {
                color: #FFF;
                text-align: center;
                font-family: ' Playfair';
                font-size: 108px;
                font-style: normal;
                font-weight: 500;
                line-height: 120px;
                transform-origin: center;
                span {
                    position: relative;
                }
                @media (max-width: 990px) {
                    font-size: 65px;
                    line-height: 65px;
                }
            }

            img {
                position: absolute;
                top: -16%;
                aspect-ratio: 0.5;
                left: 13%;
                pointer-events: none;
                z-index: 99;
                @media (max-width: 990px) {
                    top: -8%;
                    left: 27%;
                    max-height: 335px;
                }
            }
        }

        .btn-find {
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
            /* 230.769% */
            letter-spacing: 3.9px;
            color: #fff;
            text-transform: uppercase;
            text-decoration: none;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-inline: 1.5rem;
            position: relative;
            font-family: 'Termina Test Demi';
            width: fit-content;
            margin: 0 auto;
            margin-top: 40px;

            &:hover {
                &::after {
                    width: 100%;
                }
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 40%;
                height: 1px;
                background: #CC9C4A;
                transition: all .2s ease-in;
            }
        }

        .btn2 span {
            color: #ffffff;
        }

        .btn2-4 span:hover {
            color: #CC9C4A;
        }
    }

    .banner-paralax {
        height: 743px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        &::after{
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #000;
            opacity: .3;
        }
        @media (max-width: 991px) {
            height: 520px;
        }
        .paralax-content {
            max-width: 730px;
            display: flex;
            flex-direction: column;
            gap: 40px;
            position: relative;
            z-index: 9;
            .btn2 span {
                color: #ffffff;
            }

            .btn2-4 span:hover {
                color: #CC9C4A;
            }
        }

        .title {
            color: #FFF;
            text-align: center;
            /* desktop/H1 - Title */
            font-family: Playfair;
            font-size: 80px;
            font-style: normal;
            font-weight: 300;
            line-height: 65px;

            /* 81.25% */
            strong {
                color: #CC9C4A;
            }
            @media (max-width: 991px) {
                color: #FFF;
                text-align: center;
                font-size: 56px;
                font-style: normal;
                font-weight: 300;
                line-height: 50px; /* 89.286% */
            }
        }

        .text {
            color: #FFF;
            text-align: center;
            font-family: Playfair;
            font-size: 30px;
            font-style: normal;
            font-weight: 500;
            line-height: 30px;
            /* 100% */
            @media (max-width: 991px) {
                color: #FFF;
                text-align: center;
                font-size: 21px;
                font-style: normal;
                font-weight: 500;
                line-height: 21px; /* 100% */
            }
        }

        .btn-find {
            font-size: 13px;
            font-style: normal;
            font-weight: 600;
            line-height: 30px;
            /* 230.769% */
            letter-spacing: 3.9px;
            color: #fff;
            text-transform: uppercase;
            text-decoration: none;
            height: 40px;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-inline: 1.5rem;
            position: relative;
            font-family: 'Termina Test Demi';
            width: fit-content;
            margin: 0 auto;

            &:hover {
                &::after {
                    width: 100%;
                }
            }

            &::after {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                width: 40%;
                height: 1px;
                background: #CC9C4A;
                transition: all .2s ease-in;
            }
        }
    }
}
