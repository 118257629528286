#footer {
    padding-top: 74px;
    position: relative;
    z-index: 9;
    background: #fff;

    @media(min-width: 1320px) {
        .container {
            max-width: 1320px;
            margin: 0 auto;
        }
    }

    .linha-redes-logo {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .redes {
        gap: 34px;
        align-items: center;
        padding-right: 15.5rem;
        padding-bottom: 3rem;
        display: flex;

        @media(max-width: 991px) {
            gap: 14px;
            padding-right: 0;
            padding: 0 20px;
        }

        a {
            border: 1px solid #CC9C4A;
            border-radius: 50%;
            transition: all .3s ease-in-out;

            &:hover {
                background-color: #CC9C4A;

                svg {
                    filter: brightness(0) invert(1);
                }
            }
        }

        .insta,
        .yt {
            padding: .5rem;
        }
    }

    .grid-footer {
        display: grid;
        grid-template-columns: 3fr 2fr 1fr;
        margin-top: 71px;
        margin-bottom: 170px;
        padding-left: 1rem;

        @media(max-width: 991px) {
            grid-template-columns: 1fr;
            margin-bottom: 80px;
        }

        .news-footer {
            h2 {
                color: #0A0A0A;
                font-family: 'Playfair';
                font-size: 40px;
                font-style: normal;
                font-weight: 500;
                line-height: 35px;
                /* 87.5% */

                @media(max-width: 991px){
                    margin-top: 65px;
                }
            }

            p {
                color: #0A0A0A;
                font-family: 'Playfair';
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 25px;
                /* 125% */
                max-width: 65%;
                margin-top: 28px;
                margin-bottom: 29px;
            }

            .btn-find {
                cursor: pointer;
                font-size: 13px;
                font-style: normal;
                font-weight: 600;
                line-height: 30px;
                /* 230.769% */
                letter-spacing: 3.9px;
                color: #0A0A0A;
                text-transform: uppercase;
                text-decoration: none;
                height: 40px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding-inline: 1.5rem;
                position: relative;
                font-family: "termina", sans-serif;
                width: fit-content;
                left: 18%;

                &:hover {
                    &::after {
                        width: 100%;
                    }
                }

                &::after {
                    content: '';
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    width: 40%;
                    height: 1px;
                    background: #CC9C4A;
                    transition: all .2s ease-in;
                }
            }

            input {
                width: 65%;
                border: none;
                border-bottom: 2px solid #CC9C4A;
                height: 50px;
                margin-bottom: 43px;
                font-size: 20px;

                &::placeholder {
                    color: #0A0A0A;
                    font-family: "termina", sans-serif;
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 22px;
                    /* 169.231% */
                }
            }
        }

        .adress-ctt {
            h4 {
                color: #CC9C4A;
                font-family: 'Playfair';
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 35px;
                /* 175% */

                @media(max-width: 991px){
                    margin-top: 32px;
                }
            }

            p,a {
                color: #0A0A0A;
                font-family: "termina", sans-serif;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 30px;
                /* 230.769% */
                max-width: 75%;
                text-decoration: none;
            }
        }

        .site-btn {
            display: flex;
            flex-direction: column;
            padding-left: 3rem;

            @media(max-width: 991px) {
                padding-left: 0;
            }

            h4 {
                color: #CC9C4A;
                font-family: 'Playfair';
                font-size: 20px;
                font-style: normal;
                font-weight: 500;
                line-height: 35px;
                /* 175% */
            }

            a {
                color: #0A0A0A;
                font-family: "termina", sans-serif;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 37px;
                /* 284.615% */
                text-decoration: none;
                transition: all .2s ease-in;

                &:hover {
                    color: #CC9C4A;
                }
            }
        }
    }

    .rodape {
        align-items: center;
        display: flex;
        height: 91.5px;
        background: rgba(217, 217, 217, 0.62);

        .container {
            max-width: 1300px;
        }

        .linha-rodape {
            display: flex;
            justify-content: space-between;
            align-items: center;

            p {
                color: rgba(10, 10, 10, 0.49);
                font-family: "termina", sans-serif;
                font-size: 13px;
                font-style: normal;
                font-weight: 500;
                line-height: 30px;
                /* 230.769% */

                @media(max-width: 991px) {
                    font-size: 10px;
                }
            }
        }
    }
}
